import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import Flex from '@ui/Flex';
import Modal, { ModalBody } from '@ui/Modal';
import Spinner from '@ui/Spinner';

import styles from './ProgressModal.module.scss';

/**
 * Renders a blocking non-dismissable modal when toggled open. This is used when
 * form submission is in progress and the user should be prevented from
 * interacting with the UI until the request is complete.
 */
export default function CreateVersionProgressModal({ isOpen = false }: { isOpen?: boolean }) {
  const bem = useClassy(styles, 'CreateVersionProgressModal');
  const uid = useUniqueId('CreateVersionProgressModal');

  const modalContainerId = uid('progress-modal');
  const modalRef = useRef<Modal>(null);

  useEffect(() => {
    modalRef.current?.toggle(isOpen);
  }, [isOpen]);

  return (
    <>
      <Modal ref={modalRef} noDismiss target={`#${modalContainerId}`} verticalCenter>
        <ModalBody>
          <Flex align="center" className={bem('-content')} gap="sm" justify="start">
            <Spinner />
            Creating Version
          </Flex>
        </ModalBody>
      </Modal>
      {createPortal(
        <div className={bem('&', 'ModalWrapper')} data-color-mode="dark" data-testid={bem()} id={modalContainerId} />,
        document.body,
      )}
    </>
  );
}
