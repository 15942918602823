import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

const AllPagesTOCHide = ({ style }: SharedSvgProps) => (
  <svg
    className={styles.Preview}
    fill="none"
    height="62"
    style={style}
    viewBox="0 0 92 62"
    width="92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_153_4926)">
      <rect fill="url(#paint0_linear_153_4926)" height="62" rx="5" width="92" />
      <g clipPath="url(#clip1_153_4926)">
        <rect fill="#F0F0F0" height="69" rx="2.77895" width="103" x="-21" y="15" />
        <mask fill="white" id="path-3-inside-1_153_4926">
          <path d="M-21 17.7789C-21 16.2442 -19.7558 15 -18.2211 15H79.2211C80.7558 15 82 16.2442 82 17.7789V26.3368H-21V17.7789Z" />
        </mask>
        <path
          d="M-21 17.7789C-21 16.2442 -19.7558 15 -18.2211 15H79.2211C80.7558 15 82 16.2442 82 17.7789V26.3368H-21V17.7789Z"
          fill="#018EF5"
        />
        <path
          d="M-21 15H82H-21ZM82 27.3368H-21V25.3368H82V27.3368ZM-21 26.3368V15V26.3368ZM82 15V26.3368V15Z"
          fill="black"
          fillOpacity="0.1"
          mask="url(#path-3-inside-1_153_4926)"
        />
        <rect fill="white" fillOpacity="0.8" height="3" rx="1.5" width="10" x="-2.52637" y="19.1685" />
        <rect fill="black" fillOpacity="0.4" height="3" rx="1.5" width="27" x="-11" y="31.3369" />
        <rect fill="#DCDEE2" height="2" rx="1" width="73" x="-11" y="37.3369" />
        <rect fill="#DCDEE2" height="2" rx="1" width="79" x="-11" y="42.3369" />
        <rect fill="#DCDEE2" height="2" rx="1" width="69" x="-11" y="47.3369" />
        <rect fill="#DCDEE2" height="2" rx="1" width="75" x="-11" y="52.3369" />
        <rect fill="black" fillOpacity="0.4" height="3" rx="1.5" width="27" x="-11" y="57.3369" />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_153_4926"
        x1="75.4143"
        x2="95.6437"
        y1="14.2082"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
      <clipPath id="clip0_153_4926">
        <rect fill="white" height="62" rx="5" width="92" />
      </clipPath>
      <clipPath id="clip1_153_4926">
        <rect fill="white" height="69" rx="2.77895" width="103" x="-21" y="15" />
      </clipPath>
    </defs>
  </svg>
);

export default AllPagesTOCHide;
