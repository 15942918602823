import React from 'react';

import useConfirmBeforeUnload from '@core/hooks/useConfirmBeforeUnload';
import useUniqueId from '@core/hooks/useUniqueId';

import { Fieldset, FormRow, Page, PageContent } from '@routes/SuperHub/Settings/components';

import InfoPop from '@ui/InfoPop';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import { useProjectSettingsFormContext } from '../Context';

/**
 * Route component for custom login form.
 */
function CustomLogin() {
  const uid = useUniqueId('CustomLogin');
  const {
    control,
    formState: { isDirty },
  } = useProjectSettingsFormContext();

  useConfirmBeforeUnload(isDirty);

  return (
    <Page>
      <PageContent>
        <Fieldset>
          <FormRow>
            <RHFGroup control={control} id={uid('jwt-secret')} label="JWT Secret" name="custom_login.jwt_secret">
              {({ field }) => <Input {...field} readOnly size="sm" />}
            </RHFGroup>

            <RHFGroup control={control} id={uid('login-url')} isUrl label="Login URL" name="custom_login.login_url">
              {({ field }) => <Input {...field} size="sm" />}
            </RHFGroup>

            <RHFGroup
              control={control}
              description="Specify re-direct URL"
              id={uid('redirect-url')}
              isUrl
              label={
                <>
                  Logout URL
                  <InfoPop>
                    Specify a redirect URL for users logging out, allowing your auth provider&apos;s session to clear in
                    addition to ReadMe&apos;s session
                  </InfoPop>
                </>
              }
              name="custom_login.logout_url"
            >
              {({ field }) => <Input {...field} size="sm" />}
            </RHFGroup>
          </FormRow>
        </Fieldset>
        <Fieldset isCollapsible legend="Advanced Options">
          <FormRow>
            <RHFGroup
              control={control}
              description="Enter number of minutes before user has to re-authenticate"
              id={uid('user-data-expiration')}
              label="User Data Expiration"
              name="custom_login.PENDING_jwt_expiration"
            >
              {({ field }) => <Input {...field} size="sm" type="number" />}
            </RHFGroup>
          </FormRow>
        </Fieldset>
      </PageContent>
    </Page>
  );
}

export default CustomLogin;
