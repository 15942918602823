import React, { useCallback, useContext } from 'react';

import type { UserContextValue } from '@core/context';
import { UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useIntercom from '@core/hooks/useIntercom';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useProjectStore, useSuperHubStore } from '@core/store';
import { isGuidesPage, isReferencePage } from '@core/store/SuperHub/Document/util';

import Dropdown from '@ui/Dropdown';
import FeaturePopover from '@ui/FeaturePopover';
import Icon from '@ui/Icon';
import Segment, { SegmentItem } from '@ui/Segment';

import AppearanceNavigationMenu from '../../Settings/Appearance/NavigationMenu';
import BaseNav from '../BaseNav';
import BasePanel from '../BasePanel';
import { useSuperHubContext } from '../Context';
import LaunchButton from '../PlanAccess/LaunchButton';
import Transition from '../Transition';

import ProjectNavigationBreadcrumb from './Breadcrumb';
import ProjectNavigationButton from './Button';
import classes from './index.module.scss';
import ProjectMenu from './ProjectMenu';

interface ProjectNavigationProps {
  className?: string;
}

// Define end date for the breadcrumb feature popover
const BREADCRUMB_FEATURE_END_DATE = new Date('2025-04-07');

function IntercomButton() {
  const bem = useClassy(classes, 'ProjectNavigation');
  const { isGod } = useContext(UserContext) as unknown as UserContextValue;
  const { isProd } = useEnvInfo();

  /**
   * Initialize the internal readme intercom widget to allow superhub users to
   * contact readme support. This is not the same as the project's intercom
   * instance, which is configured by superhub users in the integrations
   * settings. The project intercom instance is disabled for superhub admins and
   * only visible to end users.
   */
  const isIntercomEnabled = isProd ? !isGod : true;

  const triggerIntercom = useIntercom({
    isEnabled: isIntercomEnabled,
    hideDefaultLauncher: true,
    useProjectIntercomId: false,
  });

  const handleTriggerIntercom = useCallback(() => {
    triggerIntercom();
  }, [triggerIntercom]);

  if (!isIntercomEnabled) return null;

  return (
    <ProjectNavigationButton
      aria-label="Help"
      className={bem('-help-button')}
      onClick={handleTriggerIntercom}
      showTooltip
    >
      <Icon name="help" />
    </ProjectNavigationButton>
  );
}

/**
 * Displays the project controls for the SuperHub layout,
 * which show at the root hash route.
 */
export default function ProjectNavigation({ className }: ProjectNavigationProps) {
  const bem = useClassy(classes, 'ProjectNavigation');
  const { isClient } = useEnvInfo();
  const { isGod, createdAt } = useContext(UserContext) as unknown as UserContextValue;

  const [layout, slug, doc, isEditing, routeSection, firstPage, isEnterpriseGLP] = useSuperHubStore(s => [
    s.layout,
    s.slug || '',
    s.document.data,
    s.isEditing,
    s.routeSection,
    s.sidebar.findSidebarFirstPage,
    !!s.enterprise?.isGroup && s.routeSection === 'home',
  ]);

  const [onboardingCompleted] = useProjectStore(store => [store.data.onboarding_completed]);
  const { isTrial } = useProjectPlan();
  const { browserRouterHistory } = useSuperHubContext();

  const isEditableSection = ['changelog', 'docs', 'page', 'reference', 'recipes'].includes(routeSection ?? '');
  const isSettingsLayout = ['settings', 'settings-preview'].includes(layout);
  const isMyDevelopers = layout === 'my-developers';

  const handleEdit = useCallback(
    event => {
      event.preventDefault();
      // If the user is navigated to an editable page, we take them to that page in the editor.
      // Otherwise, we take them to the docs section.
      const entrySection = isEditableSection ? routeSection : 'docs';
      browserRouterHistory.push({
        pathname: `/update/${entrySection}${slug ? `/${slug}` : ''}`,
        hash: '#',
      });
    },
    [browserRouterHistory, isEditableSection, routeSection, slug],
  );

  const handlePreview = useCallback(
    event => {
      event.preventDefault();
      const isGuideReferencePage = isGuidesPage(doc) || isReferencePage(doc);
      const redirectSlug = (isGuideReferencePage && doc.type === 'link') || !slug ? firstPage?.()?.slug : slug;
      const redirectPath = [routeSection, redirectSlug].filter(Boolean).join('/');
      browserRouterHistory.push({
        pathname: `/${redirectPath}`,
        hash: '#',
      });
    },
    [browserRouterHistory, doc, firstPage, routeSection, slug],
  );

  const handleMyDevelopers = useCallback(
    event => {
      event.preventDefault();
      const isSetupComplete = onboardingCompleted.logs;

      // Redirect to setup if My Developers setup is not complete
      const hash = !isSetupComplete ? '/my-developers/setup' : '/my-developers';
      // Use browserRouterHistory to navigate in order to trigger an unsaved changes prompt if the
      // editor form is dirty.
      browserRouterHistory.push({
        hash: `#${hash}`,
      });
    },
    [browserRouterHistory, onboardingCompleted.logs],
  );

  return (
    <Transition
      className={className}
      enter={[
        { transform: 'translateY(calc(var(--SuperHub-top-nav-height) * -1))', opacity: 0 },
        { transform: 'translateY(0)', opacity: 1 },
      ]}
      in={layout !== 'aside-standalone'}
    >
      <BasePanel label={'Project controls'}>
        <BaseNav className={bem('-container')}>
          <div className={bem('-left')}>
            {isEnterpriseGLP ? (
              <ProjectMenu />
            ) : (
              <>
                <ProjectMenu />
                <FeaturePopover
                  createdAt={createdAt}
                  description="You can now manage your versions in ReadMe Refactored!"
                  disabled={!!isGod}
                  duration={22500}
                  endDate={BREADCRUMB_FEATURE_END_DATE}
                  id="version-management-superhub"
                  theme="dark"
                  tippyProps={{
                    placement: 'bottom-start',
                    offset: [0, 15],
                  }}
                  title="Version Management"
                >
                  <ProjectNavigationBreadcrumb />
                </FeaturePopover>
              </>
            )}
          </div>

          <div className={bem('-center')}>
            {!isEnterpriseGLP && !isSettingsLayout && (
              <Segment
                circular
                className={bem('-mode-toggle', isMyDevelopers && '-mode-toggle_success')}
                ghost
                selectedIndex={isMyDevelopers ? 2 : isEditing ? 1 : 0}
                size="xs"
              >
                <SegmentItem onClick={handlePreview}>
                  <Icon name="eye" />
                  View
                </SegmentItem>
                <SegmentItem onClick={handleEdit}>
                  <Icon name="edit" size="sm" />
                  Edit
                </SegmentItem>
                <SegmentItem onClick={handleMyDevelopers}>
                  <Icon name="users" size="sm" />
                  My Developers
                </SegmentItem>
              </Segment>
            )}
          </div>

          <div className={bem('-right')}>
            {isSettingsLayout ? (
              <ProjectNavigationButton
                aria-label="Close Settings"
                onClick={() => {
                  window.location.hash = '/';
                }}
              >
                <Icon name="x" />
              </ProjectNavigationButton>
            ) : isEnterpriseGLP ? (
              <IntercomButton />
            ) : (
              <>
                <ProjectNavigationButton
                  aria-label="Content"
                  onClick={() => {
                    // Use browserRouterHistory to navigate in order to trigger an unsaved changes prompt if the
                    // editor form is dirty.
                    browserRouterHistory.push({
                      hash: '#/content',
                    });
                  }}
                  showTooltip
                >
                  <Icon name="text" />
                </ProjectNavigationButton>

                <Dropdown appendTo={isClient ? document.body : 'parent'} clickInToClose justify="end">
                  <ProjectNavigationButton aria-label="Appearance" dropdown showTooltip>
                    <Icon name="brush" />
                  </ProjectNavigationButton>
                  <AppearanceNavigationMenu />
                </Dropdown>

                {!!isTrial && <LaunchButton />}

                <IntercomButton />
              </>
            )}
          </div>
        </BaseNav>
      </BasePanel>
    </Transition>
  );
}
