import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import { type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';
import Tooltip from '@ui/Tooltip';

import classes from './index.module.scss';

/**
 * Displays a warning when the user is editing a project setting that is not versioned.
 * @todo Remove this component once glossary and appearance settings are versioned.
 */
export default function UnversionedWarning() {
  const bem = useClassy(classes, 'UnversionedWarning');
  const versionsTotal = useSuperHubStore(s => s.versions.data.total);

  const { action, section } = useParams<SuperHubHashRouteParams>();
  const unversionedSegmentLabel = section === 'glossary' ? 'Glossary' : action === 'appearance' ? 'Appearance' : null;

  return versionsTotal > 1 && !!unversionedSegmentLabel ? (
    <Tooltip
      arrow={false}
      content={
        <div className={bem('-tooltip')}>
          <p className={bem('-tooltip-description')}>Changes will be applied to all of your versions.</p>
        </div>
      }
      placement="bottom"
    >
      <Flex align="center" className={bem('&')} gap="sm">
        <Graphic name="warning" />
        <span className={bem('-label')}>{unversionedSegmentLabel} settings are not versioned.</span>
      </Flex>
    </Tooltip>
  ) : null;
}
