import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import DemoGate from '@routes/SuperHub/components/PlanAccess/DemoGate';
import { Page, PageContent } from '@routes/SuperHub/Settings/components';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';

import { useProjectSettingsFormContext, useFormGateContext } from '../Context';

import BestPractices from './BestPractices';
import classes from './style.module.scss';

function CustomJS() {
  const uid = useUniqueId('CustomJS');
  const bem = useClassy(classes, 'CustomCode');
  const { control } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();

  return (
    <Page>
      <PageContent isCondensed>
        <DemoGate access={formGate === 'demo' ? 'locked' : 'open'} feature="javascript">
          <BestPractices type="javascript" />
          <RHFGroup control={control} id={uid('js')} name="appearance.custom_code.js" size="sm">
            {({ field }) => (
              <CodeInput
                {...field}
                className={bem('-code-input')}
                initialValue={field.value}
                isDarkMode
                language="javascript"
              />
            )}
          </RHFGroup>
        </DemoGate>
      </PageContent>
    </Page>
  );
}

export default CustomJS;
