import { useSuperHubStore } from '@core/store';

import useBuildReplacedVersionPath from '../useBuildReplacedVersionPath';

interface Args {
  nextDefaultVersion: string;
  previousDefaultVersion: string;
}

/**
 * Given the version that was previously the default and the version that is now
 * the default, returns the URL to redirect to maintain the users's current
 * position. There are two situations where this redirect is applicable:
 *
 * 1. If they were on the previously default version but made another version
 *    the default (e.g. /docs/getting-started → /v1.0/docs/getting-started)
 *
 * 2. If they were previously viewing a version that they just made the new
 *    default (e.g. /v1.0/docs/getting-started → /docs/getting-started)
 *
 * If neither of those situations are applicable, `null` is returned to indicate
 * that consumers should not perform a redirect.
 */
export default function useDefaultVersionChangedRedirect({ previousDefaultVersion, nextDefaultVersion }: Args) {
  const [currentVersion] = useSuperHubStore(s => [s.versions.currentVersion]);

  const isViewingPrevDefaultVersion = currentVersion === previousDefaultVersion;
  const isViewingNextDefaultVersion = currentVersion === nextDefaultVersion;
  const shouldRedirect = isViewingPrevDefaultVersion || isViewingNextDefaultVersion;

  const buildReplacedVersionPath = useBuildReplacedVersionPath({
    defaultVersion: nextDefaultVersion,
  });

  return shouldRedirect ? buildReplacedVersionPath(currentVersion) : null;
}
