import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSuperHubContext } from './components/Context';

/**
 * When updating the hash in the BroswerRouter's history, the hash change isn't reflected in the HashRouter's history.
 * This component connects the HashRouter to the BrowserRouter by updating the HashRouter's location if it becomes out
 * of sync with the BrowserRouter's hash.
 */
export default function ConnectHashRouterToBrowserRouter({ children }) {
  const { pathname: hashRouterPath } = useLocation();
  const hashRouterHistory = useHistory();
  const { browserRouterLocation } = useSuperHubContext();

  useEffect(() => {
    const browserRouterHashPath = browserRouterLocation.hash.replace('#', '');

    if (browserRouterHashPath !== hashRouterPath) {
      hashRouterHistory.replace(browserRouterHashPath);
    }
  }, [browserRouterLocation.hash, hashRouterHistory, hashRouterPath]);

  return <>{children}</>;
}
