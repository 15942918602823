import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import {
  Fieldset,
  FormRow,
  PageContent,
  PreviewRow,
  AllPagesTOCShow,
  AllPagesTOCHide,
  Page,
} from '@routes/SuperHub/Settings/components';

import Input from '@ui/Input';
import Radio from '@ui/Radio';
import { RHFGroup } from '@ui/RHF';

import { useProjectSettingsFormContext } from '../Context';
import useLiveUpdateProjectStore from '../useLiveUpdateProjectStore';

import classes from './index.module.scss';

const fields: Path<ProjectSettingsFormValues>[] = ['appearance.table_of_contents', 'appearance.whats_next_label'];

export default function AllPages() {
  const bem = useClassy(classes, 'AllPages');
  const { control } = useProjectSettingsFormContext();

  useLiveUpdateProjectStore({ fields, control });

  return (
    <Page>
      <PageContent isCondensed>
        <Fieldset className={bem('-fieldset')} legend="Table of Contents" size="sm">
          <p className={bem('-description')}>Appears in Guides and Markdown Reference pages</p>
          <FormRow columns={1}>
            <RHFGroup control={control} name="appearance.table_of_contents" size="sm">
              {({ field }) => (
                <PreviewRow>
                  <Radio {...field} checked={field.value === 'enabled'} isCard label="Show" value="enabled">
                    <AllPagesTOCShow />
                  </Radio>
                  <Radio {...field} checked={field.value === 'disabled'} isCard label="Hide" value="disabled">
                    <AllPagesTOCHide />
                  </Radio>
                </PreviewRow>
              )}
            </RHFGroup>
          </FormRow>
        </Fieldset>
        <Fieldset className={bem('-fieldset')} legend="What's Next Label" size="sm">
          <FormRow>
            <RHFGroup
              control={control}
              description="Renames footer section with links to other pages"
              name="appearance.whats_next_label"
              size="sm"
            >
              {({ field }) => <Input {...field} placeholder="What's Next" value={field.value} />}
            </RHFGroup>
          </FormRow>
        </Fieldset>
      </PageContent>
    </Page>
  );
}
