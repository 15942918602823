import type { VersionNotificationProps } from '../../components/Notification';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Modal, { ModalFooter } from '@ui/Modal';
import { notify } from '@ui/Notification';

import VersionNotification from '../../components/Notification';
import { useEditVersionFormContext } from '../../EditVersion/Context';

import { useDeleteVersionModalContext } from './Context';
import styles from './index.module.scss';

function ErrorNotification({ apiError, name }: { apiError: VersionNotificationProps['apiError']; name: string }) {
  return (
    <VersionNotification
      apiError={apiError}
      description={
        <>
          Failed to delete v{name}. Please try again or{' '}
          <a href="mailto:support@readme.io" rel="noreferrer" target="_blank">
            reach out to support
          </a>
          .
        </>
      }
      kind="error"
      title="Problem Deleting Version"
    />
  );
}

export function SuccessNotification({ name }: { name: string }) {
  return <VersionNotification description={`v${name} has been deleted.`} kind="success" title="Version Deleted" />;
}

interface DeleteVersionModalProps {
  /** Called when version is successfully deleted. */
  onDelete?: (name: string) => void;
}

/**
 * Renders a blocking non-dismissable modal when toggled open. This is used when
 * form submission is in progress and the user should be prevented from
 * interacting with the UI until the request is complete.
 */
export default function DeleteVersionModal({ onDelete }: DeleteVersionModalProps) {
  const bem = useClassy(styles, 'DeleteVersionModal');
  const uid = useUniqueId('DeleteVersionModal');
  const deleteVersion = useSuperHubStore(s => s.versions.deleteVersion);
  const formValue = useEditVersionFormContext();
  const { isDeleteModalOpen, setIsDeleteModalOpen } = useDeleteVersionModalContext();
  const [isDeleting, setIsDeleting] = useState(false);

  const modalContainerId = uid('progress-modal');
  const modalRef = useRef<Modal>(null);
  const versionName = formValue.formState.defaultValues?.name;

  useEffect(() => {
    modalRef.current?.toggle(isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleDelete = useCallback(async () => {
    if (!versionName) {
      throw new Error('Version name is missing.');
    }

    try {
      setIsDeleting(true);
      await deleteVersion(versionName);
      notify(<SuccessNotification name={versionName} />);
      setIsDeleteModalOpen(false);
      onDelete?.(versionName);
    } catch (error) {
      notify(<ErrorNotification apiError={error} name={versionName} />);
    } finally {
      setIsDeleting(false);
    }
  }, [deleteVersion, onDelete, setIsDeleteModalOpen, versionName]);

  return (
    <>
      <Modal
        ref={modalRef}
        className={bem('-container')}
        noDismiss
        onClose={() => setIsDeleteModalOpen(false)}
        size="sm"
        target={`#${modalContainerId}`}
        verticalCenter
      >
        <header className={bem('-header')}>
          <h1 className={bem('-title')}>Delete Version</h1>
        </header>
        <p className={bem('-body')}>
          Are you sure you want to delete version v{versionName}? This action cannot be undone.
        </p>
        <ModalFooter align="center" gap="sm" justify="center">
          <Button
            aria-label="Cancel"
            disabled={isDeleting}
            ghost
            kind="minimum"
            onClick={() => modalRef.current?.toggle(false)}
            size="md"
          >
            Cancel
          </Button>
          <Button
            aria-label="Delete"
            kind="destructive"
            loading={isDeleting ? 'Deleting' : false}
            onClick={handleDelete}
            size="md"
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      {createPortal(
        <div className={bem('&', 'ModalWrapper')} data-color-mode="dark" data-testid={bem()} id={modalContainerId} />,
        document.body,
      )}
    </>
  );
}
