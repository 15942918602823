import { createContext, useContext } from 'react';

interface DeleteVersionModalContextValue {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Contains state that controls whether the edit form's delete modal is
 * currently open or not.
 */
const DeleteVersionModalContext = createContext<DeleteVersionModalContextValue | null>(null);
DeleteVersionModalContext.displayName = 'DeleteVersionModalContext';

export default DeleteVersionModalContext;

/**
 * Contains state that controls whether the edit form's delete modal is
 * currently open or not.
 */
export const useDeleteVersionModalContext = () => {
  const value = useContext(DeleteVersionModalContext);
  if (!value) throw new Error('DeleteVersionModalContext is not provided');
  return value;
};
