import type { ReadVersionType } from '@readme/api/src/mappings/version/types';

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import DeleteVersionModalContext from '../DeleteVersion/Modal/Context';
import RenameVersionModalContext from '../RenameVersion/Modal/Context';

interface EditVersionFormProviderProps {
  children: React.ReactNode;
  /**
   * Configures the form to use this version as its initial state. This is for
   * the case when we are editing a version vs creating a new one.
   */
  version: ReadVersionType['data'];
}

/**
 * Creates a new React Hook Form that loads the initial state for either an
 * existing version or when creating a new one. Renders the form context
 * provider that grants API access to all descendants.
 */
export default function EditVersionFormProvider({ children, version }: EditVersionFormProviderProps) {
  const formValue = useForm<EditVersionFormProviderProps['version']>({ defaultValues: version });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  useEffect(() => {
    formValue.reset(version);
  }, [formValue, version]);

  return (
    <FormProvider {...formValue}>
      <DeleteVersionModalContext.Provider value={{ isDeleteModalOpen, setIsDeleteModalOpen }}>
        <RenameVersionModalContext.Provider value={{ isRenameModalOpen, setIsRenameModalOpen }}>
          {children}
        </RenameVersionModalContext.Provider>
      </DeleteVersionModalContext.Provider>
    </FormProvider>
  );
}

/**
 * Returns the React Hook Form context for our create/edit forms.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useEditVersionFormContext = () => {
  const value = useFormContext<EditVersionFormProviderProps['version']>();
  if (!value) throw new Error('EditVersionFormProvider is not provided');
  return value;
};
