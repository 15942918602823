import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import React, { useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { isDark, isLight, darken } from '@Hub/Theme';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore } from '@core/store';

import {
  Fieldset,
  FormRow,
  Page,
  PageContent,
  PreviewRow,
  ColorModeAutoSVG,
  ColorModeDarkSVG,
  ColorModeLightSVG,
  HeaderLogoDefaultSVG,
  HeaderLogoLargeSVG,
  HeaderTypeGradientSVG,
  HeaderTypeImageSVG,
  HeaderTypeLineSVG,
  HeaderTypeSolidSVG,
} from '@routes/SuperHub/Settings/components';

import ColorPicker from '@ui/ColorPicker';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import type { ImageUploaderData } from '@ui/ImageUploader';
import ImageUploader from '@ui/ImageUploader';
import Radio from '@ui/Radio';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';

import { useProjectSettingsFormContext } from '../Context';
import useLiveUpdateProjectStore from '../useLiveUpdateProjectStore';

const fields: Path<ProjectSettingsFormValues>[] = [
  'appearance.brand.link_color',
  'appearance.brand.primary_color',
  'appearance.brand.theme',
  'appearance.header.gradient_color',
  'appearance.header.overlay.fill',
  'appearance.header.overlay.image',
  'appearance.header.overlay.position',
  'appearance.header.overlay.type',
  'appearance.header.type',
  'appearance.logo.dark_mode',
  'appearance.logo.favicon',
  'appearance.logo.main',
  'appearance.logo.size',
];

function ThemeEditor() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { parent } = project;

  const uid = useUniqueId('ThemeEditor');
  const {
    control,
    formState: { dirtyFields },
  } = useProjectSettingsFormContext();

  useLiveUpdateProjectStore({ fields, control });

  const headerType = useWatch({ control, name: 'appearance.header.type' });
  const overlayType = useWatch({ control, name: 'appearance.header.overlay.type' });
  const primaryColor = useWatch({ control, name: 'appearance.brand.primary_color' });
  const linkColor = useWatch({ control, name: 'appearance.brand.link_color' });

  const [logoColor] = useProjectStore(s => [
    typeof s.data.appearance.logo.main === 'object' ? s.data.appearance.logo.main?.color : null,
  ]);

  const isPrimaryTooLight = useMemo(() => primaryColor && isLight(primaryColor), [primaryColor]);
  const [isLinkTextTooLight, isLinkTextTooDark] = useMemo(
    () => [linkColor && isLight(linkColor), linkColor && isDark(linkColor)],
    [linkColor],
  );

  return (
    <Page>
      <PageContent isCondensed>
        <Fieldset legend="Logo" size="sm">
          <FormRow columns={2}>
            <RHFGroup
              control={control}
              description="Transparent image backgrounds work best"
              id={uid('header-logo')}
              label="Default Logo"
              name="appearance.logo.main"
              size="sm"
            >
              {({ field }) => (
                <Flex gap={0} layout="col">
                  <ImageUploader
                    data={field.value as ImageUploaderData}
                    id={field.id}
                    kind="secondary"
                    onFinish={data => field.onChange(data)}
                    outline={false}
                    preview
                    size="sm"
                  >
                    <Icon name="upload" />
                    Upload Image
                  </ImageUploader>
                </Flex>
              )}
            </RHFGroup>

            <RHFGroup control={control} label="Logo Size" name={'appearance.logo.size'} size="sm">
              {({ field }) => (
                <PreviewRow>
                  <Radio {...field} checked={field.value === 'default'} isCard label="Default (24px)" value="default">
                    <HeaderLogoDefaultSVG />
                  </Radio>
                  <Radio {...field} checked={field.value === 'large'} isCard label="Large (40px)" value="large">
                    <HeaderLogoLargeSVG />
                  </Radio>
                </PreviewRow>
              )}
            </RHFGroup>

            <RHFGroup control={control} id={uid('favicon')} label="Favicon" name="appearance.logo.favicon" size="sm">
              {({ field }) => (
                <Flex gap={0} layout="col">
                  <ImageUploader
                    data={field.value as ImageUploaderData}
                    id={field.id}
                    kind="secondary"
                    onFinish={data => field.onChange(data)}
                    outline={false}
                    preview
                    size="sm"
                  >
                    <Icon name="upload" />
                    Upload Image
                  </ImageUploader>
                </Flex>
              )}
            </RHFGroup>
          </FormRow>
        </Fieldset>
        <Fieldset legend="Brand" size="sm">
          <FormRow columns={2}>
            <RHFGroup
              control={control}
              data-testid="primary-color-parent"
              id={uid('primary-color')}
              label="Primary Color"
              name="appearance.brand.primary_color"
              size="sm"
              warningMessage={
                headerType === 'line' && isPrimaryTooLight ? 'Try a darker color to show up on white' : null
              }
            >
              {({ field }) => (
                <ColorPicker
                  circular={false}
                  className={'Input'}
                  color={field.value}
                  data-testid="primary-color"
                  debounceOnChange={10}
                  id={field.id}
                  onChange={field.onChange}
                  placeholder={logoColor}
                />
              )}
            </RHFGroup>

            <RHFGroup
              control={control}
              data-testid="link-color-parent"
              id={uid('link-color')}
              label="Link Color"
              name="appearance.brand.link_color"
              size="sm"
              warningMessage={
                isLinkTextTooLight
                  ? 'Try a darker color to show up on white'
                  : isLinkTextTooDark
                    ? 'Try a color lighter than the text color so links are clearly marked'
                    : null
              }
            >
              {({ field }) => (
                <ColorPicker
                  circular={false}
                  className={'Input'}
                  color={field.value}
                  data-testid="link-color"
                  debounceOnChange={10}
                  id={field.id}
                  onChange={field.onChange}
                  placeholder={primaryColor || logoColor}
                />
              )}
            </RHFGroup>
          </FormRow>
          {!parent && (
            <FormRow columns={1}>
              <RHFGroup control={control} label="Theme" name={'appearance.brand.theme'} size="sm">
                {({ field }) => (
                  <PreviewRow>
                    <Radio {...field} checked={field.value === 'system'} isCard label="Same as System" value="system">
                      <ColorModeAutoSVG isLineHeader={headerType === 'line'} />
                    </Radio>
                    <Radio {...field} checked={field.value === 'light'} isCard label="Light" value="light">
                      <ColorModeLightSVG isLineHeader={headerType === 'line'} />
                    </Radio>
                    <Radio {...field} checked={field.value === 'dark'} isCard label="Dark" value="dark">
                      <ColorModeDarkSVG isLineHeader={headerType === 'line'} />
                    </Radio>
                  </PreviewRow>
                )}
              </RHFGroup>
            </FormRow>
          )}
        </Fieldset>
        <Fieldset legend="Header" size="sm">
          <FormRow columns={1}>
            <RHFGroup control={control} label="Type" name={'appearance.header.type'} size="sm">
              {({ field }) => (
                <PreviewRow>
                  <Radio checked={field.value === 'solid'} isCard label="Solid Color" {...field} value="solid">
                    <HeaderTypeSolidSVG />
                  </Radio>
                  <Radio checked={field.value === 'gradient'} isCard label="Gradient" {...field} value="gradient">
                    <HeaderTypeGradientSVG />
                  </Radio>
                  <Radio checked={field.value === 'overlay'} isCard label="Overlay" {...field} value="overlay">
                    <HeaderTypeImageSVG />
                  </Radio>
                  <Radio checked={field.value === 'line'} isCard label="Line" {...field} value="line">
                    <HeaderTypeLineSVG />
                  </Radio>
                </PreviewRow>
              )}
            </RHFGroup>
          </FormRow>
          {headerType === 'gradient' && (
            <FormRow>
              <FormGroup label="Gradient Colors">
                <Flex align="stretch" gap="sm" layout="col">
                  <RHFGroup
                    control={control}
                    data-testid="gradient-color-parent-1"
                    id={uid('gradient-color-1')}
                    name="appearance.brand.primary_color"
                    size="sm"
                  >
                    {({ field }) => (
                      <ColorPicker
                        circular={false}
                        className={'Input'}
                        color={field.value}
                        data-testid="link-color"
                        debounceOnChange={10}
                        id={field.id}
                        onChange={field.onChange}
                        placeholder={logoColor}
                      />
                    )}
                  </RHFGroup>

                  <RHFGroup
                    control={control}
                    data-testid="gradient-color-parent-2"
                    id={uid('gradient-color-2')}
                    name="appearance.header.gradient_color"
                    size="sm"
                  >
                    {({ field }) => (
                      <ColorPicker
                        circular={false}
                        className={'Input'}
                        color={
                          !field.value && primaryColor && !dirtyFields.appearance?.header?.gradient_color
                            ? darken(primaryColor)
                            : field.value
                        }
                        data-testid="link-color"
                        debounceOnChange={10}
                        id={field.id}
                        onChange={field.onChange}
                        placeholder={darken(logoColor || '')}
                      />
                    )}
                  </RHFGroup>
                </Flex>
              </FormGroup>
            </FormRow>
          )}
          {headerType === 'line' && (
            <FormRow>
              <RHFGroup
                control={control}
                description="Used for Line header in dark mode"
                helpMessage="Dark theme logo automatically replaces default logo when your website is being viewed in dark mode"
                id={uid('dark-logo')}
                label="Dark Theme Logo"
                name="appearance.logo.dark_mode"
                size="sm"
              >
                {({ field }) => (
                  <Flex gap={0} layout="col">
                    <ImageUploader
                      data={field.value as ImageUploaderData}
                      id={field.id}
                      kind="secondary"
                      onFinish={data => field.onChange(data)}
                      outline={false}
                      preview
                      size="sm"
                    >
                      <Icon name="upload" />
                      Upload Image
                    </ImageUploader>
                  </Flex>
                )}
              </RHFGroup>
            </FormRow>
          )}
          {headerType === 'overlay' && (
            <FormRow>
              <FormGroup label="Image Options">
                <FormRow columns={2} isCondensed>
                  <RHFGroup
                    control={control}
                    description="Preset or custom image"
                    id={uid('overlay-type')}
                    name="appearance.header.overlay.type"
                    size="sm"
                  >
                    {({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { label: 'Custom', value: 'custom' },
                          { label: 'Triangles', value: 'triangles' },
                          { label: 'Blueprint', value: 'blueprint' },
                          { label: 'Grain', value: 'grain' },
                          { label: 'Map', value: 'map' },
                          { label: 'Circuits', value: 'circuits' },
                        ]}
                        size="sm"
                      />
                    )}
                  </RHFGroup>

                  {overlayType === 'custom' && (
                    <RHFGroup control={control} id={uid('overlay-image')} name="appearance.header.overlay.image">
                      {({ field }) => (
                        <ImageUploader
                          data={field.value as ImageUploaderData}
                          kind="secondary"
                          onFinish={data => field.onChange(data)}
                          outline={false}
                          preview
                          size="sm"
                        >
                          <Icon name="upload" />
                          Upload Image
                        </ImageUploader>
                      )}
                    </RHFGroup>
                  )}
                </FormRow>

                {overlayType === 'custom' && (
                  <FormRow columns={2} isCondensed>
                    <RHFGroup
                      control={control}
                      description="Custom image fill"
                      id={uid('overlay-fill')}
                      name="appearance.header.overlay.fill"
                      size="sm"
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          options={[
                            { label: 'Auto', value: 'auto' },
                            { label: 'Tile', value: 'tile' },
                            { label: 'Tile X', value: 'tile-x' },
                            { label: 'Tile Y', value: 'tile-y' },
                            { label: 'Cover', value: 'cover' },
                            { label: 'Contain', value: 'contain' },
                          ]}
                          size="sm"
                        />
                      )}
                    </RHFGroup>

                    <RHFGroup
                      control={control}
                      description="Custom image position"
                      id={uid('overlay-position')}
                      name="appearance.header.overlay.position"
                      size="sm"
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          options={[
                            { label: 'Top Left', value: 'top-left' },
                            { label: 'Top Center', value: 'top-center' },
                            { label: 'Top Right', value: 'top-right' },
                            { label: 'Center Left', value: 'center-left' },
                            { label: 'Center Center', value: 'center-center' },
                            { label: 'Center Right', value: 'center-right' },
                            { label: 'Bottom Left', value: 'bottom-left' },
                            { label: 'Bottom Center', value: 'bottom-center' },
                            { label: 'Bottom Right', value: 'bottom-right' },
                          ]}
                          size="sm"
                        />
                      )}
                    </RHFGroup>
                  </FormRow>
                )}
              </FormGroup>
            </FormRow>
          )}
        </Fieldset>
      </PageContent>
    </Page>
  );
}

export default ThemeEditor;
