import type { ExternalRepositoryType } from '@readme/api/src/routes/gitSync/types';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ProjectContext, type ProjectContextValue } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext, fetcher } from '@core/hooks/useReadmeApi';

import { useSuperHubActionSlotContext } from '@routes/SuperHub/components/Context';
import { Fieldset, FormRow } from '@routes/SuperHub/Settings/components';

import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Select from '@ui/Select';
import SmartLink from '@ui/SmartLink';
import Spinner from '@ui/Spinner';

import classes from './index.module.scss';

function providerLabel(provider: string) {
  switch (provider) {
    case 'github':
      return 'GitHub';
    default:
      return provider;
  }
}

// eslint-disable-next-line consistent-return
function manageConnectionLink(
  provider: string,
  selectedOrg: { connection: ExternalRepositoryType['connection']; owner: ExternalRepositoryType['owner'] } | undefined,
) {
  // `selectedOrg` may come through as undefined on the first
  // render when React is still fetching the data. In that case,
  // we should return an empty string to avoid a broken link.
  // The link of course won't go anywhere in that case.
  if (!selectedOrg) return '';

  // eslint-disable-next-line default-case
  switch (provider) {
    case 'github':
      // eslint-disable-next-line default-case
      switch (selectedOrg.owner.type) {
        case 'User':
          return `https://github.com/settings/installations/${selectedOrg.connection.installation_id}`;
        case 'Organization':
          return `https://github.com/organizations/${selectedOrg.owner.login}/settings/installations/${selectedOrg.connection.installation_id}`;
      }
  }
}

export default function GitConnectionChooseRepo({ onConnectionSave }: { onConnectionSave: () => void }) {
  const bem = useClassy(classes, 'GitConnectionChooseRepo');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { subdomain } = project;
  const actionSlot = useSuperHubActionSlotContext();
  const providerType = project.git.sync.linked_repository?.provider_type || 'github';
  const [isSyncing, setIsSyncing] = useState(false);
  const [connectionError, setConnectionError] = useState<{ detail?: string; title: string } | null>(null);
  const history = useHistory();

  const {
    data: { data = [] } = {},
    isLoading: isRepositoryDataLoading,
    error: loadRepositoriesError,
  } = useReadmeApiNext<{
    data: ExternalRepositoryType[];
  }>(`/git_sync/repositories?providers=${providerType}&per_page=100`, {
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  // If the repositories list api 403s, it likely means the user
  // has uninstalled the github app, or there are no connections
  // available to us. Forcing a refresh here takes them back to the
  // onboarding screen.
  if (loadRepositoriesError?.status === 403) {
    history.go(0);
  }

  // Dedupe the list of organizations by provider type - provider id - connection id
  const orgs = useMemo(() => {
    return Array.from(
      new Map(
        data.map(({ owner, connection, provider }) => [
          `${provider.type}-${provider.id}-${connection.id}`,
          { owner, connection, provider },
        ]),
      ).values(),
    );
  }, [data]);

  // Default to the first organization in the list
  const [selectedOrg, setSelectedOrg] = useState(data.length ? orgs[0] : undefined);

  // Filter the repositories by the selected organization
  const repositories = useMemo(() => {
    return data.length && selectedOrg
      ? data.filter(
          repo =>
            repo.provider.type === selectedOrg.provider.type &&
            repo.provider.id === selectedOrg.provider.id &&
            repo.connection.id === selectedOrg.connection.id,
        )
      : [];
  }, [data, selectedOrg]);

  // Default to the first organization in the list
  useEffect(() => {
    if (orgs.length && !selectedOrg) {
      setSelectedOrg(orgs[0]);
    }
  }, [orgs, selectedOrg]);

  const handleOrgSelection = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const orgIndex = orgs.length
        ? orgs.findIndex(
            ({ provider, connection }) => `${provider.type}-${provider.id}-${connection.id}` === e.currentTarget.value,
          )
        : 0;
      const org = orgs[orgIndex] || undefined;
      setSelectedOrg(org);
    },
    [orgs],
  );

  const handleSync = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      const repositoryToLink = repositories.find(
        repo => `${repo.provider.type}-${repo.provider.id}-${repo.connection.id}-${repo.id}` === e.currentTarget.value,
      );
      if (!repositoryToLink) {
        return;
      }

      setIsSyncing(true);
      setConnectionError(null);

      try {
        await fetcher(`/${subdomain}/api-next/v2/git_sync/repositories/linked`, {
          method: 'POST',
          body: JSON.stringify({
            connection_id: repositoryToLink.connection.id,
            id: repositoryToLink.id,
            provider_type: repositoryToLink.provider.type,
            provider_id: repositoryToLink.provider.id,
            name: repositoryToLink.name,
          }),
        });

        project.mutate({
          git: {
            sync: {
              linked_repository: repositoryToLink,
            },
          },
        });
        onConnectionSave();
      } catch (error) {
        setConnectionError({
          title: error?.info?.title || error.message,
          detail: error?.info?.detail,
        });
      } finally {
        setIsSyncing(false);
      }
    },
    [project, repositories, subdomain, onConnectionSave],
  );

  const organizationSelectOptions = useMemo(
    () =>
      orgs.map(({ provider, connection, owner }) => {
        return {
          label: owner.login,
          value: `${provider.type}-${provider.id}-${connection.id}`,
        };
      }),
    [orgs],
  );

  const isReady = !isRepositoryDataLoading;

  const ErrorMessage = connectionError ? (
    <>
      <p>{connectionError.title}</p>
      <p>{connectionError.detail}</p>
    </>
  ) : null;

  return (
    <Fieldset legend={<Flex justify="start">{providerLabel(providerType)} Connection</Flex>}>
      {!isReady ? (
        <Spinner className={bem('-spinner')} size="md" />
      ) : (
        <FormRow columns={2}>
          <FormGroup
            description={
              <SmartLink href={`/${subdomain}/api-next/v2/git_sync/github/init`}>Add New Organization</SmartLink>
            }
            label="Choose Organization"
          >
            <Select
              defaultValue={selectedOrg?.owner.login}
              onChange={handleOrgSelection}
              options={organizationSelectOptions}
              size="sm"
            />
          </FormGroup>
          <FormGroup
            description={
              <SmartLink href={manageConnectionLink(providerType, selectedOrg)} rel="noreferrer" target="_blank">
                <Flex align="center" gap="xs">
                  <Icon name="github-filled" />
                  Manage Connection
                  <Icon name="arrow-up-right" />
                </Flex>
              </SmartLink>
            }
            errorMessage={ErrorMessage}
            label={<>Choose Repository {!!isSyncing && <Spinner size="sm" />}</>}
          >
            {!!actionSlot && (
              <div className={classes['GitConnectionSetup-repoList']}>
                {repositories.map(
                  repo => (
                    <button
                      key={`${repo.provider?.id}-${repo.connection.id}-${repo.id}`}
                      className={classes['GitConnectionSetup-repoList-button']}
                      disabled={isSyncing}
                      onClick={handleSync}
                      value={`${repo.provider.type}-${repo.provider.id}-${repo.connection.id}-${repo.id}`}
                    >
                      <div className={classes['GitConnectionSetup-repoList-button-content']}>
                        <span>{repo.name}</span>
                        <Icon className={classes['GitConnectionSetup-repoList-button-icon']} name="arrow-right" />
                      </div>
                    </button>
                  ),
                  actionSlot,
                )}
              </div>
            )}
            <Flex align="center" className={classes['GitConnection-info']} gap="xs" justify="start">
              <Icon name="info" size="sm" />
              Must be an empty repository to sync successfully.
            </Flex>
          </FormGroup>
        </FormRow>
      )}
    </Fieldset>
  );
}
