import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import classes from './index.module.scss';

interface LockedFieldProps {
  children: React.ReactNode;
  className?: string;
}
const LockedField = ({ children, className }: LockedFieldProps) => {
  const bem = useClassy(classes, 'LockedField');
  return (
    <div className={bem('&', className)}>
      <Icon className={bem('-icon')} name="lock" />
      <div className={bem('-content')}>{children}</div>
    </div>
  );
};

export default LockedField;
