import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import APIMethod from '@ui/API/Method';
import useApiReferenceMetricsFilters from '@ui/Metrics/Filters/hooks/useApiReferenceMetricsFilters';
import useIsApiReferenceFilteredView from '@ui/Metrics/Filters/hooks/useIsApiReferenceFilteredView';

import styles from './index.module.scss';

export default function ApiReferenceBreadcrumb() {
  const bem = useClassy(styles, 'TopBar');

  const documentData = useSuperHubStore(s => s.document.data);
  const apiReferenceFilters = useApiReferenceMetricsFilters();
  const { isApiReferenceFilteredView } = useIsApiReferenceFilteredView();

  // if it's an API reference filtered view and we know the title of
  // the API reference, display it as a breadcrumb
  if (isApiReferenceFilteredView && documentData) {
    return (
      <div className={bem('-title-breadcrumb')}>
        <APIMethod fixedWidth type={apiReferenceFilters!.method} />
        <div>{documentData?.title}</div>
      </div>
    );
  }

  return null;
}
