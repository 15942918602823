import type { ReducedLinkedRepositoryType } from '@readme/api/src/routes/gitSync/types';
import type { ProjectDocument } from '@readme/backend/models/project/types';
import type { GitSyncConnection } from '@readme/iso';

import React, { useCallback, useContext } from 'react';

import { ProjectContext, type ProjectContextValue } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';

import { Page, PageContent } from '@routes/SuperHub/Settings/components';

import Spinner from '@ui/Spinner';

import GitConnectionChooseRepo from './ChooseRepo';
import classes from './index.module.scss';
import GitConnectionLinkedRepo from './LinkedRepo';
import GitConnectionSetup from './Setup';

function renderContent(
  onConnectionSave: () => void,
  installedConnection?: GitSyncConnection,
  connectedRepo?: ReducedLinkedRepositoryType | null,
  installationRequest?: ProjectDocument['git']['sync']['installationRequest'],
) {
  if (installedConnection) {
    if (connectedRepo) {
      return <GitConnectionLinkedRepo installedConnection={installedConnection} linkedRepo={connectedRepo} />;
    }

    // If we don't have a connected repo and our installation is inactive, bounce users to setup
    if (!installedConnection.active) return <GitConnectionSetup />;

    return <GitConnectionChooseRepo onConnectionSave={onConnectionSave} />;
  }
  return <GitConnectionSetup installationRequest={installationRequest} />;
}

function GitConnection() {
  const bem = useClassy(classes, 'GitConnection');
  const { project } = useContext(ProjectContext) as ProjectContextValue;

  const installationRequest = project.git.sync.installationRequest;
  const connections = project.git.sync.connections;
  const installedConnection = connections?.[0];
  const isInstalled = !!installedConnection;

  const { data, isLoading, error, mutate } = useReadmeApiNext<{
    data: ReducedLinkedRepositoryType;
  } | null>(
    '/git_sync/repositories/linked',
    {
      swr: {
        revalidateOnFocus: true,
        shouldRetryOnError: true,
      },
    },
    isInstalled,
  );

  const onConnectionSave = useCallback(() => {
    // Refetch the connected repo
    mutate();
  }, [mutate]);

  const connectedRepo = error?.status === 404 ? null : data?.data;
  const isConnected = !!connectedRepo;
  const isReady = !isInstalled || !isLoading;

  return (
    <Page renderActionSlot={!!isReady && !!isInstalled && !isConnected}>
      <PageContent className={bem(!isReady && '_loading')}>
        {!isReady ? (
          <Spinner className={bem('-spinner')} size="md" />
        ) : (
          renderContent(onConnectionSave, installedConnection, connectedRepo, installationRequest)
        )}
      </PageContent>
    </Page>
  );
}

export default GitConnection;
