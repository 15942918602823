import React, { createContext, useContext } from 'react';

export type FormGateContextValue = 'demo' | 'none' | 'plan' | 'trial';

interface FormGateContextProps {
  children: React.ReactNode;
  gate: FormGateContextValue;
}

const FormGateContext = createContext<FormGateContextValue | null>(null);

/**
 * Provides `FormGateContext` and makes it available to all children.
 */
export const FormGateContextProvider = ({ children, gate }: FormGateContextProps) => {
  return <FormGateContext.Provider value={gate}>{children}</FormGateContext.Provider>;
};

/**
 * Indicates the type of plan access gate to use for the form.
 * - `demo`: The form displays a DemoGate if the user does not have access to the feature.
 * - `plan`: The form displays a PayGate if the user does not have access to the feature.
 * - `trial`: The form will allow the user to access the feature during a trial period.
 * - `none`: The form does not have a gate.
 */
export function useFormGateContext() {
  const value = useContext(FormGateContext);
  if (!value) throw new Error('FormGateContext.Provider is missing');
  return value;
}
