import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';

import BasePanel from '@routes/SuperHub/components/BasePanel';
import useAutoScrollToTop from '@routes/SuperHub/components/useAutoScrollToTop';
import { type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import GitConnection from '../forms/GitConnection';
import ProjectSettingsForm from '../forms/Project';
import AllPages from '../forms/Project/AllPages';
import ApiReference from '../forms/Project/ApiReference';
import Changelog from '../forms/Project/Changelog';
import { CSS, HTML, JS } from '../forms/Project/CustomCode';
import Footer from '../forms/Project/Footer';
import Navigation from '../forms/Project/Navigation';
import ThemeEditor from '../forms/Project/ThemeEditor';

import classes from './index.module.scss';
import AppearanceNavigationDropdown from './NavigationDropdown';

/**
 * Displays navigation and routes for managing live preview-able such as Theme, Navigation, and Custom CSS.
 */
export default function Appearance() {
  const bem = useClassy(classes, 'Appearance');

  const { section } = useParams<SuperHubHashRouteParams>();
  const { planFeatures } = useProjectPlan();

  useAutoScrollToTop();

  return (
    <BasePanel label="Project appearance controls">
      <div className={bem('&')}>
        <nav className={bem('-nav')}>
          <AppearanceNavigationDropdown section={section || ''} />
        </nav>

        <Switch>
          <Route path="/:action(appearance)/:section(all-pages)">
            <ProjectSettingsForm>
              <AllPages />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(api-reference)">
            <ProjectSettingsForm>
              <ApiReference />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(changelog)">
            <ProjectSettingsForm formGate={planFeatures.changelogs ? 'none' : 'demo'}>
              <Changelog />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(custom-css)">
            <ProjectSettingsForm formGate={planFeatures.stylesheet ? 'none' : 'demo'}>
              <CSS />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(custom-html)">
            <ProjectSettingsForm formGate={planFeatures.extra_html ? 'none' : 'demo'}>
              <HTML />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(custom-js)">
            <ProjectSettingsForm formGate={planFeatures.javascript ? 'none' : 'demo'}>
              <JS />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(footer)">
            <ProjectSettingsForm formGate={planFeatures.whitelabel ? 'none' : 'demo'}>
              <Footer />
            </ProjectSettingsForm>
          </Route>
          <Route path="/configure/appearance/github-sync">
            <GitConnection />
          </Route>
          <Route path="/:action(appearance)/:section(navigation)">
            <ProjectSettingsForm formGate={planFeatures.usability ? 'none' : 'demo'}>
              <Navigation />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(appearance)/:section(theme-editor)">
            <ProjectSettingsForm>
              <ThemeEditor />
            </ProjectSettingsForm>
          </Route>

          <Route exact path="/:action(appearance)">
            <Redirect to="appearance/theme-editor" />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}
