import type { ReadVersionType } from '@readme/api/src/mappings/version/types';

import React, { useEffect, useState } from 'react';

import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import LazyTippy from '@ui/LazyTippy';

import { useDeleteVersionModalContext } from '../DeleteVersion/Modal/Context';
import { useRenameVersionModalContext } from '../RenameVersion/Modal/Context';

import EditVersionFormProvider, { useEditVersionFormContext } from './Context';
import EditVersionForm from './Form';

function Content({ className }: EditVersionProps) {
  const ephemeralVersion = useSuperHubStore(s => s.versions.ephemeralVersion);
  const formValue = useEditVersionFormContext();
  const { isDeleteModalOpen } = useDeleteVersionModalContext();
  const { isRenameModalOpen } = useRenameVersionModalContext();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (ephemeralVersion) {
      setVisible(false);
    }
  }, [ephemeralVersion]);

  return (
    <LazyTippy
      appendTo={document.body}
      arrow={false}
      content={<EditVersionForm />}
      interactive
      maxWidth={320}
      offset={[0, 10]}
      onClickOutside={() => {
        if (formValue.formState.isSubmitting || isDeleteModalOpen || isRenameModalOpen) return;
        setVisible(false);
      }}
      placement="bottom-end"
      role="dialog"
      visible={visible}
      zIndex={10002} // Must be less than our Modal z-index.
    >
      <Button
        aria-label="Edit Version"
        className={className}
        kind="secondary"
        onClick={() => setVisible(true)}
        size="xs"
        text
        uppercase={false}
      >
        Edit
      </Button>
    </LazyTippy>
  );
}

interface EditVersionProps {
  className?: string;
  /** Version that is being updated. */
  version: ReadVersionType['data'];
}

/**
 * Renders the "edit" button that opens up a form inside a popover. Form submits
 * when values change except for other actions that require more care such as
 * rename, delete or make default. Form stays open while submission is in
 * progress but otherwise can be dismissed by clicking outside the overlay.
 */
export default function EditVersion(props: EditVersionProps) {
  return (
    <EditVersionFormProvider version={props.version}>
      <Content {...props} />
    </EditVersionFormProvider>
  );
}
