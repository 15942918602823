import { getFeaturePlan, capitalize } from '@readme/iso';
import React from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';
import useUniqueId from '@core/hooks/useUniqueId';

import PlanAccessBadge from '@routes/SuperHub/components/PlanAccess/Badge';
import PayGate from '@routes/SuperHub/components/PlanAccess/PayGate';
import { Fieldset, FormRow, Page, PageContent } from '@routes/SuperHub/Settings/components';

import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';
import Toggle from '@ui/Toggle';

import { useFormGateContext, useProjectSettingsFormContext } from '../Context';

import classes from './index.module.scss';

/**
 * Route component for `settings/health-check`.
 */
function HealthCheck() {
  const uid = useUniqueId('HealthCheck');
  const bem = useClassy(classes, 'HealthCheck');
  const { control } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();
  const { showTrialIndicator } = useProjectPlan();
  const provider = useWatch({ control, name: 'health_check.provider' });
  const manualApiStatus = useWatch({ control, name: 'health_check.settings.manual.status' });

  const showTrialBadge = showTrialIndicator('healthCheck');
  const upgradePlan = getFeaturePlan('healthCheck');

  return (
    <>
      <Page>
        <PayGate access={formGate === 'plan' ? 'locked' : 'open'} feature="health-check">
          <PageContent>
            {!!showTrialBadge && (
              <PlanAccessBadge access="trial" className={bem('-trial-badge')} plan={upgradePlan} tooltip={true} />
            )}
            <Fieldset>
              <FormRow>
                <RHFGroup control={control} id={uid('provider')} label="Provider" name="health_check.provider">
                  {({ field }) => (
                    <Select
                      {...field}
                      options={[
                        { label: 'None', value: 'none' },
                        { label: "Manually Change Your API's Health", value: 'manual' },
                        { label: 'statuspage.io', value: 'statuspage' },
                      ]}
                      size="sm"
                    />
                  )}
                </RHFGroup>
              </FormRow>

              {provider === 'manual' && (
                <FormRow columns={2}>
                  <RHFGroup
                    control={control}
                    description="Changes take up to 1 minute to take effect."
                    id={uid('api-status')}
                    label="API Status"
                    name="health_check.settings.manual.status"
                  >
                    {({ field }) => (
                      <Toggle
                        {...field}
                        checked={field.value === 'up'}
                        kind="red-green"
                        onChange={e => {
                          field.onChange(e.target.checked ? 'up' : 'down');
                        }}
                        type="toggle"
                      >
                        {!!field.value && capitalize(field.value)}
                      </Toggle>
                    )}
                  </RHFGroup>

                  {manualApiStatus === 'down' && (
                    <RHFGroup
                      control={control}
                      description="Displayed to users when your API is down."
                      id={uid('info-url')}
                      isUrl
                      label="Info URL"
                      name="health_check.settings.manual.url"
                    >
                      {({ field }) => <Input {...field} placeholder="https://" size="sm" />}
                    </RHFGroup>
                  )}
                </FormRow>
              )}
              {provider === 'statuspage' && (
                <FormRow>
                  <RHFGroup
                    control={control}
                    description="A non-URL value like nz3r09cr7dk1"
                    id={uid('statuspage-id')}
                    label="Status Page ID"
                    name="health_check.settings.statuspage.id"
                    required
                  >
                    {({ field }) => <Input {...field} size="sm" />}
                  </RHFGroup>
                </FormRow>
              )}
            </Fieldset>
          </PageContent>
        </PayGate>
      </Page>
    </>
  );
}

export default HealthCheck;
