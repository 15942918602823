import React from 'react';

import { Link } from '@Dash/Link';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Title from '@ui/Title';

import leftStickers from './Images/left-stickers.png';
import rightStickers from './Images/right-stickers.png';
import classes from './style.module.scss';

export function StartScreen() {
  const bem = useClassy(classes, 'StartScreen');

  return (
    <Flex align="center" className={bem('&')} gap="lg" justify="center" layout="col">
      <Flex align="center" gap="lg" layout="col">
        <Title level={4} text="Create a new component" />
        <Button
          aria-label="Create a New Component"
          className={bem('-create-btn')}
          ghost
          // @ts-ignore
          is={Link}
          kind="contrast"
          to="new"
        >
          <Flex align="center" gap="sm" justify="center" layout="col">
            <div className={bem('-create-btn_circle')}>
              <Icon aria-hidden="true" name="plus" size={32}></Icon>
            </div>
            <div className={bem('-create-btn_h1')}>Create New</div>
            <div className={bem('-create-btn_h2')}>Start building something beautiful</div>
          </Flex>
        </Button>
      </Flex>
      <Flex align="center" className={bem('-marketplace')} gap="xs" justify="center" layout="col">
        <Button
          aria-label="Browse Marketplace"
          className={bem('-marketplace-btn')}
          ghost
          href="https://github.com/readmeio/marketplace"
          kind="contrast"
          rel="noreferrer"
          target="_blank"
        >
          Browse Marketplace <Icon aria-label="Opens in a new tab" name="arrow-up-right" />
        </Button>
        <div>A public repository for building, sharing, and accessing community-created components</div>
        <Flex className={bem('-marketplace-stickers')} justify="space-between">
          <img alt="Marketplace Stickers" src={leftStickers} />
          <img alt="Marketplace Stickers" src={rightStickers} />
        </Flex>
      </Flex>
    </Flex>
  );
}
