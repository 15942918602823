import React from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import { Page, PageContent } from '@routes/SuperHub/Settings/components';

import Box from '@ui/Box';
import Collapsible from '@ui/Collapsible';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext } from '../Context';

import styles from './index.module.scss';
import IntegrationFieldset from './IntegrationFieldset';

function Integrations() {
  const bem = useClassy(styles, 'Integrations');
  const uid = useUniqueId('Integrations');
  const { control } = useProjectSettingsFormContext();

  // Watched field values for conditional rendering
  const intercomAppId = useWatch({ control, name: 'integrations.intercom.app_id' });
  const intercomSecureModeKey = useWatch({ control, name: 'integrations.intercom.secure_mode.key' });
  const intercomSecureModeEmailOnly = useWatch({ control, name: 'integrations.intercom.secure_mode.email_only' });
  const segmentKey = useWatch({ control, name: 'integrations.segment.key' });
  const segmentDomain = useWatch({ control, name: 'integrations.segment.domain' });
  const localizeKey = useWatch({ control, name: 'integrations.localize.key' });

  return (
    <Page>
      <PageContent>
        <IntegrationFieldset legend="Google" legendGraphic="google">
          <RHFGroup
            control={control}
            id={uid('google-analytics')}
            label="Analytics"
            name="integrations.google.analytics"
          >
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>

          <RHFGroup
            control={control}
            id={uid('google-verify')}
            label="Site Verification"
            name="integrations.google.site_verification"
          >
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Bing" legendGraphic="bing">
          <RHFGroup control={control} id={uid('bing')} label="Site Verification" name="integrations.bing.verify">
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Intercom" legendGraphic="intercom">
          <Flex align="stretch" gap="sm" layout="col">
            <RHFGroup
              control={control}
              description={
                intercomAppId?.length ? (
                  <p>
                    You&apos;ll need the <strong>Acquire</strong> and <strong>Support</strong> plans for Intercom to
                    work properly.{' '}
                    <a
                      href="https://docs.readme.com/main/docs/intercom#section-which-plans-are-supported"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Learn More
                      <Icon name="arrow-up-right" />
                    </a>
                  </p>
                ) : null
              }
              id={uid('intercom')}
              label="App ID"
              name="integrations.intercom.app_id"
            >
              {({ field }) => <Input {...field} data-testid="intercom-app-id" size="sm" />}
            </RHFGroup>

            {!!intercomAppId?.length && (
              <div className={bem('-details')}>
                <Collapsible
                  opened={!!intercomSecureModeKey?.length || intercomSecureModeEmailOnly}
                  summary="Use Intercom Secure Mode"
                >
                  <Box className={bem('-details-content')} kind="rule">
                    <RHFGroup
                      control={control}
                      id={uid('intercom-key')}
                      label="Intercom Secret Key"
                      name="integrations.intercom.secure_mode.key"
                    >
                      {({ field }) => <Input {...field} size="sm" />}
                    </RHFGroup>

                    <RHFGroup
                      control={control}
                      description="Identify users by email address instead of a unique ID. Integrates better with your existing Intercom, but possibly less secure."
                      id={uid('intercom-email')}
                      name="integrations.intercom.secure_mode.email_only"
                    >
                      {({ field: { value, ...fieldProps } }) => (
                        <Toggle {...fieldProps} checked={value} size="sm">
                          <span>Users are identified by email addresses</span>
                        </Toggle>
                      )}
                    </RHFGroup>
                  </Box>
                </Collapsible>
              </div>
            )}
          </Flex>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Zendesk" legendGraphic="zendesk">
          <RHFGroup control={control} id={uid('zendesk')} label="Subdomain" name="integrations.zendesk.subdomain">
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Segment" legendGraphic="segment">
          <Flex align="stretch" gap="xs" layout="col">
            <RHFGroup control={control} id={uid('segment-key')} label="API Key" name="integrations.segment.key">
              {({ field }) => <Input data-testid="segment-api-key" {...field} size="sm" />}
            </RHFGroup>

            {!!segmentKey && (
              <div className={bem('-details')}>
                <Collapsible opened={!!segmentDomain?.length} summary="Use Segment Domain Proxy">
                  <Box className={bem('-details-content')} kind="rule">
                    <RHFGroup
                      control={control}
                      description={
                        <a
                          href="https://docs.readme.com/main/docs/segment#using-a-custom-domain-with-segment"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Learn More
                          <Icon name="arrow-up-right" />
                        </a>
                      }
                      id={uid('segment-proxy')}
                      label="Segment Domain Proxy"
                      name="integrations.segment.domain"
                    >
                      {({ field }) => <Input {...field} size="sm" />}
                    </RHFGroup>
                  </Box>
                </Collapsible>
              </div>
            )}
          </Flex>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Localize" legendGraphic="localize">
          <RHFGroup
            control={control}
            description={
              localizeKey?.length ? (
                <p>
                  You&apos;ll need to{' '}
                  <a href="https://localizejs.com/phrases" rel="noreferrer" target="_blank">
                    Approve Phrases
                    <Icon name="arrow-up-right" />
                  </a>{' '}
                  before they&apos;ll show up.
                </p>
              ) : null
            }
            id={uid('localize')}
            label="App Key"
            name="integrations.localize.key"
          >
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Heap" legendGraphic="heap">
          <RHFGroup control={control} id={uid('heap')} label="App ID" name="integrations.heap.id">
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Koala" legendGraphic="koala">
          <RHFGroup control={control} id={uid('koala')} label="API Key" name="integrations.koala.key">
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="Typekit" legendGraphic="typekit">
          <RHFGroup control={control} id={uid('typekit')} label="API Key" name="integrations.typekit.key">
            {({ field }) => <Input {...field} size="sm" />}
          </RHFGroup>
        </IntegrationFieldset>

        <IntegrationFieldset legend="reCaptcha" legendGraphic="recaptcha">
          <div>
            <RHFGroup
              control={control}
              description={
                <p>
                  We use reCaptcha invisible v2 to detect spam in our community forums.{' '}
                  <a href="https://docs.readme.com/main/docs/recaptcha" rel="noreferrer" target="_blank">
                    Learn more <Icon name="arrow-up-right" />
                  </a>
                </p>
              }
              id={uid('recaptcha-site')}
              label="Site Key"
              name="integrations.recaptcha.site_key"
            >
              {({ field }) => <Input {...field} size="sm" />}
            </RHFGroup>

            <RHFGroup
              control={control}
              id={uid('recaptcha-secret')}
              label="Secret Key"
              name="integrations.recaptcha.secret_key"
            >
              {({ field }) => <Input {...field} size="sm" />}
            </RHFGroup>
          </div>
        </IntegrationFieldset>
      </PageContent>
    </Page>
  );
}

export default Integrations;
