import type { CustomBlockMgmtItem } from '../types';

import { getFeaturePlan } from '@readme/iso';
import React, { useCallback, useEffect, useRef } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useSuperHubStore } from '@core/store';

import PlanAccessBanner from '@routes/SuperHub/components/PlanAccess/Banner';

import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';

import { useCustomBlockMgmtContext } from '../Context';
import Filter from '../Filter';
import SidebarNavItem from '../SidebarNavItem';

import classes from './style.module.scss';

interface SidebarNavProps {
  currentItem: CustomBlockMgmtItem | null;
  hasNextPage: boolean;
  isLoading: boolean;
  items: CustomBlockMgmtItem[];
  loadNextPage: () => void;
  totalItemCount: number | undefined;
}

// Height dimension of each item in the list
const ITEM_SIZE = 80;
const ITEM_GAP = 10;

// Number of rows remaining before triggering data loading on scroll
const ROW_THRESHOLD = 5;

function SidebarNav({ currentItem, items, loadNextPage, isLoading, hasNextPage, totalItemCount }: SidebarNavProps) {
  const bem = useClassy(classes, 'SidebarNav');
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);
  const [isSuperHubAdmin] = useSuperHubStore(state => [state.isSuperHubAdmin]);
  const { showTrialIndicator } = useProjectPlan();
  const { type } = useCustomBlockMgmtContext();

  // Only reusable content is gated by plan and would show a trial banner
  const showTrialBanner = isSuperHubAdmin && type === 'content' && showTrialIndicator('reusableContent');
  const upgradePlan = getFeaturePlan('reusableContent');

  // Calculate the list container height whenever it reports any changes
  // to its dimensions.
  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      const containerRect = entry.target.getBoundingClientRect();
      if (!containerRect) return;
      setHeight(containerRect.height);
    });

    if (listContainerRef.current) {
      resizeObserver.observe(listContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [listContainerRef, items]);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length],
  );

  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isLoading ? () => {} : loadNextPage;

  return (
    <nav className={bem('&', showTrialBanner && '_withBanner')}>
      <Filter />
      {isLoading ? (
        <div className={bem('_loading')}>
          <Spinner size="lg" />
        </div>
      ) : type === 'component' && totalItemCount === 0 ? (
        <div className={bem('_empty')}>
          <p>
            No components <Icon name="frown" />
          </p>
        </div>
      ) : itemCount === 0 ? (
        <div className={bem('_empty')}>
          <p>No results found</p>
        </div>
      ) : (
        <div ref={listContainerRef} className={bem('-list')}>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={ROW_THRESHOLD}
          >
            {({ onItemsRendered, ref }) => (
              <VirtualList
                ref={ref}
                height={height}
                itemCount={itemCount}
                itemData={items}
                itemSize={ITEM_SIZE + ITEM_GAP}
                onItemsRendered={onItemsRendered}
                width="100%"
              >
                {({ index, style }) => {
                  const item = items[index];
                  return (
                    <SidebarNavItem
                      key={index}
                      isActive={currentItem?.tag === item?.tag}
                      isLoading={!isItemLoaded(index)}
                      item={items[index]}
                      style={index < itemCount - 1 ? style : { ...style, height: `${ITEM_SIZE}px` }}
                    />
                  );
                }}
              </VirtualList>
            )}
          </InfiniteLoader>
        </div>
      )}
      {!!showTrialBanner && (
        <div className={bem('-banner')}>
          <PlanAccessBanner kind="trial" plan={upgradePlan} />
        </div>
      )}
    </nav>
  );
}

export default SidebarNav;
