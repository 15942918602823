import { useMemo } from 'react';

import { useMetricsStore, useSuperHubStore } from '@core/store';

/**
 * If we are in superhub on an API reference page, returns the metrics `method`
 * and `path` filters that correspond to that API reference. If we aren't in superhub,
 * aren't on a reference page, returns `null`. If the my dev panel is expanded,
 * `null` is additionally returned if no such filters exist so that we don't
 * drop the user into an empty state.
 */
export default function useApiReferenceMetricsFilters() {
  const availableFilters = useMetricsStore(s => s.myDevelopers.allAvailableFilters);

  const { isSuperhub, documentData, routeSection, isMyDevPanelExpanded } = useSuperHubStore(s => ({
    isSuperhub: s.isSuperHub,
    documentData: s.document.data,
    routeSection: s.routeSection,
    isMyDevPanelExpanded: s.isMyDevPanelExpanded,
  }));

  const { apiReferencePath, apiReferenceMethod } = useMemo(() => {
    const apiContext = documentData && 'api' in documentData ? documentData.api : null;

    return { apiReferencePath: apiContext?.path, apiReferenceMethod: apiContext?.method };
  }, [documentData]);

  // look through `allAvailableFilters` to see if a path and method
  // filter exist for that API reference operation
  const hasFiltersAvailable = useMemo(() => {
    const pathFilters = availableFilters?.find(filterGroup => filterGroup.name === 'path');
    const hasPathFilter = pathFilters?.data.find(filter => filter.name === apiReferencePath);

    const methodFilters = availableFilters?.find(filterGroup => filterGroup.name === 'method');
    const hasMethodFilter = methodFilters?.data.find(
      filter => filter.name.toLowerCase() === apiReferenceMethod?.toLowerCase(),
    );

    return !!hasPathFilter && !!hasMethodFilter;
  }, [apiReferenceMethod, apiReferencePath, availableFilters]);

  const apiReferenceMetricsFilters = useMemo(() => {
    // The feature to apply filters based on the current API reference page is
    // only applicable if we in superhub on a reference route
    const isSuperhubReferenceSectionActive = isSuperhub && routeSection === 'reference';
    // In the expanded my developers view, we should only return filters for the
    // current API reference page if such filters exist as available options.
    // Otherwise it's frustrating for users to land on the My Developers tab
    // automatically in an empty state. But in the collapsed my developers view,
    // we should always return filters since it makes the connection between the
    // My Developers panel and above API reference content more consistent and
    // clear, even in the "no data" case.
    const shouldApplyApiReferenceFilters = isMyDevPanelExpanded
      ? hasFiltersAvailable && isSuperhubReferenceSectionActive
      : isSuperhubReferenceSectionActive;

    // if we should apply filters and we have filter values, return them
    if (shouldApplyApiReferenceFilters && apiReferencePath && apiReferenceMethod) {
      return {
        path: apiReferencePath,
        method: apiReferenceMethod.toUpperCase() as Uppercase<typeof apiReferenceMethod>,
      };
    }

    // if not, return null so that consumers know we shouldn't try to
    // programmatically filter down or display a breadcrumb
    return null;
  }, [apiReferenceMethod, apiReferencePath, hasFiltersAvailable, isMyDevPanelExpanded, isSuperhub, routeSection]);

  return apiReferenceMetricsFilters;
}
