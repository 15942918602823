import React, { useContext } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import {
  ManageWebhookCustomVariables,
  ManageWebhookSecurityVariables,
  ManageWebhookServerVariables,
  ManageWebhookVariablesContainer,
} from '@routes/MyDevelopers/Setup/PersonalizedDocs/ManageWebhookVariables';
import useIsWebhookSetupComplete from '@routes/MyDevelopers/Setup/PersonalizedDocs/useIsWebhookSetupComplete';
import WebhookEnterpriseConfig from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookEnterpriseConfig';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import PersonalizedDocsLayout from './Layout';
import styles from './style.module.scss';

export default function PersonalizedDocs() {
  const bem = useClassy(styles, 'PersonalizedDocs');

  const [parent = false, subdomain] = useProjectStore(s => [s.data.parent, s.data.subdomain]);
  const { domainFull } = useContext(ConfigContext) as ConfigContextValue;
  const isEnterprise = !!parent;
  const isWebhookSetupComplete = useIsWebhookSetupComplete();

  if (isEnterprise) {
    return (
      <PersonalizedDocsLayout>
        <div className={bem('-enterprise')}>
          <WebhookEnterpriseConfig parentSubdomain={parent} />
        </div>
      </PersonalizedDocsLayout>
    );
  }

  return (
    <PersonalizedDocsLayout>
      <Flex className={bem('-container')} layout="col">
        <ManageWebhookVariablesContainer>
          <ManageWebhookServerVariables />
          <ManageWebhookSecurityVariables />
          <ManageWebhookCustomVariables />
        </ManageWebhookVariablesContainer>

        <div className={bem('-separator')} />

        <Flex className={bem('-footer')} gap={10}>
          {!!isWebhookSetupComplete && (
            <Tooltip content="This will force refresh your Personalized Docs data">
              <Button href={`${domainFull}/go/${subdomain}`} kind="secondary" outline size="sm">
                Test Personalized Docs
                <Icon name="arrow-right" />
              </Button>
            </Tooltip>
          )}
          <Button kind="secondary" outline size="sm" to="/content/personalized-docs/setup">
            Edit Configuration <Icon name="settings" />
          </Button>
          <Button
            href="https://docs.readme.com/main/docs/personalized-docs"
            kind="secondary"
            outline
            size="sm"
            target="_blank"
          >
            Help <Icon name="help-circle" />
          </Button>
        </Flex>
      </Flex>
    </PersonalizedDocsLayout>
  );
}
