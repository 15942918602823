import { SEMVER_REGEX } from '@readme/iso';
import { useCallback } from 'react';

import { useProjectStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';

const SEMVER_PATH_SEGMENT_REGEX = new RegExp(`/v${SEMVER_REGEX.source}`);

interface Args {
  /**
   * Optional version to consider as the default version when building the path.
   * If not provided, the `default_version` from the project store will be used.
   * Default versions do not have a version segment in their path.
   */
  defaultVersion?: string;
}

/**
 * Returns a function that can be used to replace the version segment of the
 * current URL with the provided `newVersion`. This is helpful to keep the user
 * on the same page of the application when a destructive action is made to the
 * current version (e.g. it is renamed or made the default).
 */
export default function useBuildReplacedVersionPath({ defaultVersion: argsDefaultVersion }: Args = {}) {
  const defaultVersion = useProjectStore(s => argsDefaultVersion || s.data.default_version.name);
  const { browserRouterLocation } = useSuperHubContext();
  const { pathname, hash } = browserRouterLocation;

  return useCallback(
    (newVersion: string) => {
      // default versions don't have a version segment in the path
      const nextVersionPathSegment = newVersion === defaultVersion ? '' : `/v${newVersion}`;
      const pathnameWithReplacedVersion = pathname.match(SEMVER_PATH_SEGMENT_REGEX)
        ? // if the current path already has a version segment, replace it
          pathname.replace(SEMVER_PATH_SEGMENT_REGEX, nextVersionPathSegment)
        : // if the current path does not already have a version segment, prepend one
          `${nextVersionPathSegment}${pathname}`;

      return `${pathnameWithReplacedVersion}${hash}`;
    },
    [defaultVersion, hash, pathname],
  );
}
