import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import React from 'react';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

import useUniqueId from '@core/hooks/useUniqueId';

import DemoGate from '@routes/SuperHub/components/PlanAccess/DemoGate';
import { Page, PageContent } from '@routes/SuperHub/Settings/components';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext, useFormGateContext } from '../Context';
import useLiveUpdateProjectStore from '../useLiveUpdateProjectStore';

const fields: Path<ProjectSettingsFormValues>[] = ['appearance.footer.readme_logo'];

export default function Footer() {
  const uid = useUniqueId('Footer');
  const { control } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();
  const readmeLogo = useWatch({ control, name: 'appearance.footer.readme_logo' });

  useLiveUpdateProjectStore({ fields, control });

  return (
    <Page>
      <PageContent isCondensed>
        <DemoGate access={formGate === 'demo' ? 'locked' : 'open'} feature="footer">
          <RHFGroup
            control={control}
            id={uid('branding')}
            label="Branding"
            name="appearance.footer.readme_logo"
            size="sm"
          >
            {({ field }) => (
              <Toggle
                {...field}
                checked={field.value === 'hide'}
                isLabelMuted
                label={
                  <span>
                    <strong>Hide</strong> ReadMe logo in site footer
                  </span>
                }
                onChange={event => {
                  field.onChange(event.target.checked ? 'hide' : 'show');
                }}
                size="sm"
              />
            )}
          </RHFGroup>

          {readmeLogo === 'hide' && (
            <RHFGroup
              control={control}
              description={
                <>
                  <p>
                    Will appear in a <code>footer</code> element on all pages. Use{' '}
                    <code>{'<div class=”rm-Container”>'}</code> if you don&apos;t want a full-width footer.
                  </p>
                  <p>
                    If you want to edit CSS or JS use the{' '}
                    <Link to="/configure/appearance/custom-css-js/css">Custom CSS/JS</Link> settings.
                  </p>
                </>
              }
              id={uid('footer-html')}
              label="Footer HTML"
              name="appearance.custom_code.html.home_footer"
              size="sm"
            >
              {({ field }) => <CodeInput {...field} initialValue={field.value} isDarkMode language="html" />}
            </RHFGroup>
          )}
        </DemoGate>
      </PageContent>
    </Page>
  );
}
