import type { VersionNotificationProps } from '../components/Notification';
import type { ReadVersionType } from '@readme/api/src/mappings/version/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import { getDirtyValues } from '@routes/SuperHub/Settings/forms/Project/utils';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import { notify } from '@ui/Notification';
import { RHFGroup } from '@ui/RHF';
import Timestamp from '@ui/Timestamp';
import Toggle from '@ui/Toggle';

import VersionNotification from '../components/Notification';
import DeleteVersion from '../DeleteVersion';
import useDefaultVersionChangedRedirect from '../hooks/useDefaultVersionChangedRedirect';
import RenameVersion from '../RenameVersion';

import { useEditVersionFormContext } from './Context';
import styles from './Form.module.scss';

function ErrorNotification({ apiError, name }: { apiError: VersionNotificationProps['apiError']; name: string }) {
  return (
    <VersionNotification
      apiError={apiError}
      description={
        <>
          Failed to update v{name}. Please try again or{' '}
          <a href="mailto:support@readme.io" rel="noreferrer" target="_blank">
            reach out to support
          </a>
          .
        </>
      }
      kind="error"
      title="Problem Updating Version"
    />
  );
}

function SuccessNotification({ name }: { name: string }) {
  return <VersionNotification description={`v${name} has been updated`} kind="success" title="Version Updated" />;
}

interface EditVersionFormProps {
  /** Called when version is successfully updated. */
  onSuccess?: (version: ReadVersionType['data']) => void;
}

/**
 * Renders the individual form fields for editing a version. Must be wrapped
 * inside a `EditVersionForm` context to gain access to all the React Hook
 * Form methods and controls.
 */
export default function EditVersionForm({ onSuccess }: EditVersionFormProps) {
  const bem = useClassy(styles, 'EditVersionForm');
  const uid = useUniqueId('EditVersionForm');
  const defaultVersion = useProjectStore(s => s.data.default_version.name);
  const [updateEphemeralVersion, updateVersion] = useSuperHubStore(s => [
    s.versions.updateEphemeralVersion,
    s.versions.updateVersion,
  ]);
  const formValue = useEditVersionFormContext();

  /** Whether the form contains a `default` version or not. */
  const isDefaultVersion = formValue.formState.defaultValues?.privacy?.view === 'default';
  const {
    name: versionName = '',
    base: versionBase = null,
    updated_at: versionUpdatedAt = '',
  } = formValue.formState.defaultValues || {};

  const defaultVersionChangedRedirect = useDefaultVersionChangedRedirect({
    previousDefaultVersion: defaultVersion,
    nextDefaultVersion: versionName,
  });

  const handleSubmit = formValue.handleSubmit(async data => {
    const defaultName = formValue.formState.defaultValues?.name;
    if (!defaultName) {
      throw new Error('Original version name is missing.');
    }

    try {
      const dirtyData = getDirtyValues(formValue.formState.dirtyFields, data);
      // if no values were changed, return early
      if (!Object.keys(dirtyData).length) return;

      const response = await updateVersion(defaultName, {
        ...dirtyData,
        display_name: dirtyData.display_name ?? undefined,
      });
      formValue.reset(data);
      notify(<SuccessNotification name={response.data.name} />);
      onSuccess?.(response.data);

      // if the privacy was changed to default, perform a possible redirect
      if (dirtyData.privacy?.view === 'default' && defaultVersionChangedRedirect) {
        window.location.assign(defaultVersionChangedRedirect);
      }
    } catch (error) {
      notify(<ErrorNotification apiError={error} name={defaultName} />);
    }
  });

  return (
    <form aria-label={`Edit version ${versionName}`} autoComplete="off" data-color-mode="dark" onSubmit={handleSubmit}>
      <Menu className={bem()} data-testid={bem()}>
        <MenuItem description="Visibility" focusable={false} />
        <RHFGroup control={formValue.control} id={uid('privacy-view')} name="privacy.view" required>
          {({ field }) => (
            <>
              <MenuItem
                active={field.value === 'public' || field.value === 'default'}
                aria-label="Make Version Public"
                description={isDefaultVersion ? 'Default versions must be public' : undefined}
                disabled={isDefaultVersion}
                onClick={() => {
                  if (field.value === 'public') return;
                  field.onChange('public');
                  handleSubmit();
                }}
                TagName="button"
              >
                Public
              </MenuItem>
              <MenuItem
                active={field.value === 'hidden'}
                aria-label="Make Version Hidden"
                disabled={isDefaultVersion}
                onClick={() => {
                  if (field.value === 'hidden') return;
                  field.onChange('hidden');
                  handleSubmit();
                }}
                TagName="button"
              >
                Hidden
              </MenuItem>
            </>
          )}
        </RHFGroup>

        <MenuDivider />
        <MenuItem description="Metadata" focusable={false} />
        <MenuItem focusable={false}>
          <RHFGroup control={formValue.control} id={uid('release-stage')} name="release_stage" required>
            {({ field }) => (
              <Toggle
                {...field}
                checked={field.value === 'beta'}
                label="Beta"
                onChange={event => {
                  field.onChange(event.target.checked ? 'beta' : 'release');
                  handleSubmit();
                }}
                reverse
                type="toggle"
              />
            )}
          </RHFGroup>
        </MenuItem>
        <MenuItem focusable={false}>
          <RHFGroup control={formValue.control} id={uid('state')} name="state" required>
            {({ field }) => (
              <Toggle
                {...field}
                aria-label="Deprecated"
                checked={field.value === 'deprecated'}
                description={isDefaultVersion ? "Can't deprecate default versions" : undefined}
                descriptionLayout="col"
                disabled={isDefaultVersion}
                label="Deprecated"
                onChange={event => {
                  field.onChange(event.target.checked ? 'deprecated' : 'current');
                  handleSubmit();
                }}
                reverse
                type="toggle"
              />
            )}
          </RHFGroup>
        </MenuItem>

        <MenuDivider />
        <MenuItem focusable={false}>
          <RHFGroup
            control={formValue.control}
            description="Optional"
            id={uid('display-name')}
            label="Display Name"
            name="display_name"
            size="sm"
          >
            {({ field, formState }) => (
              <Input
                {...field}
                onBlur={() => {
                  if (field.value === formState.defaultValues?.display_name) return;
                  handleSubmit();
                }}
                size="sm"
              />
            )}
          </RHFGroup>
        </MenuItem>

        <MenuDivider />
        <RHFGroup control={formValue.control} id={uid('privacy-view')} name="privacy.view" required>
          {({ field }) => {
            return (
              <MenuItem
                {...field}
                active={isDefaultVersion}
                aria-label="Make Version Default"
                description="Version shown when opening your docs"
                disabled={isDefaultVersion}
                icon={<Icon aria-hidden="true" name="star" />}
                onClick={() => {
                  if (field.value === 'default') return;
                  field.onChange('default');
                  handleSubmit();
                }}
                TagName="button"
              >
                Make Default
              </MenuItem>
            );
          }}
        </RHFGroup>
        <RenameVersion onSuccess={onSuccess} />
        <MenuItem
          icon={<Icon aria-hidden="true" name="plus" />}
          onClick={() => updateEphemeralVersion(versionName)}
          TagName="button"
        >
          Fork New Version
        </MenuItem>
        <DeleteVersion />

        <MenuDivider />
        <MenuItem
          description={
            <Flex gap={2} layout="col">
              <span>
                Updated <Timestamp value={new Date(versionUpdatedAt)} />
              </span>
              {!!versionBase && <span className={bem('-forked-from')}>Forked from v{versionBase}</span>}
            </Flex>
          }
          focusable={false}
        ></MenuItem>
      </Menu>
    </form>
  );
}
