import type { ReducedLinkedRepositoryType, SyncStatusListType } from '@readme/api/src/routes/gitSync/types';
import type { GitSyncConnection } from '@readme/iso';

import { format } from 'date-fns';
import React, { useContext, useMemo } from 'react';

import { ProjectContext, type ProjectContextValue } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';

import { Fieldset, FormRow } from '@routes/SuperHub/Settings/components';

import Alert from '@ui/Alert';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';

import classes from './index.module.scss';

function providerLabel(provider: string) {
  switch (provider) {
    case 'github':
      return 'GitHub';
    default:
      return provider;
  }
}

// eslint-disable-next-line consistent-return
function manageConnectionLink(
  provider: string,
  installedConnection: GitSyncConnection,
  connectedRepo: ReducedLinkedRepositoryType,
) {
  const installationId = installedConnection.installation_id;

  // eslint-disable-next-line default-case
  switch (provider) {
    case 'github':
      // eslint-disable-next-line default-case
      switch (connectedRepo.organization.type) {
        case 'User':
          // @ts-ignore-next-line github has an installationId
          return `https://github.com/settings/installations/${installationId}`;
        case 'Organization':
          // @ts-ignore-next-line github has an installationId
          return `https://github.com/organizations/${connectedRepo.organization.name}/settings/installations/${installationId}`;
      }
  }
}

function getLatestSync(syncStatus: SyncStatusListType[]) {
  const lastSyncs = syncStatus
    .filter(sync => !!sync.lastSync?.completedAt)
    .sort((a, b) => {
      // @ts-ignore We're checking for completedAt above
      return new Date(b.lastSync.completedAt).getTime() - new Date(a.lastSync.completedAt).getTime();
    });

  return lastSyncs[0] || null;
}

export default function GitConnectionLinkedRepo({
  installedConnection,
  linkedRepo,
}: {
  installedConnection: GitSyncConnection;
  linkedRepo: ReducedLinkedRepositoryType;
}) {
  const bem = useClassy(classes, 'GitConnectionLinkedRepo');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const provider = project.git.sync.linked_repository?.provider_type || 'github';

  const { data: syncStatus, isLoading: isSyncStatusLoading } = useReadmeApiNext<{
    data: SyncStatusListType[];
  } | null>('/git_sync/syncs', {
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
      refreshInterval: 30 * 1000,
    },
  });

  const latestSync = useMemo(() => {
    if (isSyncStatusLoading || !syncStatus?.data) {
      return null;
    }

    return getLatestSync(syncStatus.data);
  }, [syncStatus, isSyncStatusLoading]);

  const connectedByDateText = format(new Date(linkedRepo.linked_at), 'M/d/yy');
  const latestSyncDateText = latestSync?.lastSync?.completedAt
    ? format(new Date(latestSync.lastSync.completedAt), 'M/d/yy h:mm a')
    : null;

  const title = `There's a problem with your ${providerLabel(provider)} Connection`;
  const subtitle = (
    <>
      ReadMe can no longer access your project. Syncing will resume when the connection is restored.{' '}
      <a
        className={bem('-link')}
        href={manageConnectionLink(provider, installedConnection, linkedRepo)}
        rel="noreferrer"
        target="_blank"
      >
        Reinstall <Icon name="arrow-up-right" />
      </a>
    </>
  );

  return (
    <div className={bem('&')}>
      <Fieldset legend={<Flex>{providerLabel(provider)} Connection</Flex>}>
        {!linkedRepo.organization.active && (
          <FormRow columns={1}>
            <Flex gap="xs">
              <Alert subtitle={subtitle} title={title} type="danger" />
            </Flex>
          </FormRow>
        )}
        <span className={bem('-forms', !linkedRepo.organization.active && '_inactive')}>
          <FormRow columns={2}>
            <FormGroup label="Access">
              <Flex gap="xs">
                <Button
                  href={manageConnectionLink(provider, installedConnection, linkedRepo)}
                  kind="secondary"
                  rel="noreferrer"
                  size="sm"
                  target="_blank"
                  uppercase={false}
                >
                  <Icon name="github-filled" />
                  Manage Connection
                  <Icon name="arrow-up-right" />
                </Button>
              </Flex>
            </FormGroup>
          </FormRow>

          <FormRow columns={1}>
            <FormGroup label="Connected Repository">
              <Box className={bem('-repo-details')} kind="rule" theme="dark">
                <Flex gap="xs">
                  <Button
                    className={bem('&-repo-details-link')}
                    href={linkedRepo.url}
                    kind="secondary"
                    rel="noreferrer"
                    size="sm"
                    target="_blank"
                    text
                  >
                    <Icon name="book" />
                    {linkedRepo.organization.name}/{linkedRepo.name}
                  </Button>
                </Flex>
                <Flex align="center" className={bem('-repo-details-last-sync')} gap="xs" justify="start">
                  Last synced {isSyncStatusLoading ? <Spinner /> : latestSyncDateText}
                </Flex>
                <Flex className={bem('-repo-details-connected-by')}>
                  Connected by {linkedRepo.linked_by} on {connectedByDateText}
                </Flex>
              </Box>
            </FormGroup>
          </FormRow>
        </span>
      </Fieldset>
    </div>
  );
}
