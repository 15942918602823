import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useConfirmBeforeUnload from '@core/hooks/useConfirmBeforeUnload';
import useDashLinkUrl from '@core/hooks/useDashLinkUrl';
import useReadmeApi from '@core/hooks/useReadmeApi';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';
import PayGate from '@routes/SuperHub/components/PlanAccess/PayGate';
import { Fieldset, FormRow, Page, PageContent } from '@routes/SuperHub/Settings/components';

import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import { RHFGroup } from '@ui/RHF';
import type { SelectOptionProps } from '@ui/Select';
import Select from '@ui/Select';
import Textarea from '@ui/Textarea';

import { useFormGateContext, useProjectSettingsFormContext } from '../Context';

import styles from './index.module.scss';

/**
 * Project settings for custom error pages and redirects.
 */
function ErrorPages() {
  const uid = useUniqueId('ErrorPages');
  const bem = useClassy(styles, 'ErrorPages');
  const { browserRouterHistory } = useSuperHubContext();
  const apiBaseUrlWithoutVersion = useSuperHubStore(s => s.apiBaseUrlWithoutVersion);
  const parentSubdomain = useProjectStore(s => s.data.parent);
  const dashLinkUrl = useDashLinkUrl();

  const {
    control,
    formState: { isDirty },
  } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();

  const { data: customPageSidebar = [] } = useReadmeApi<GitSidebarCategory[]>(
    '/versions/stable/sidebar?page_type=custom_page',
    {
      apiBaseUrl: apiBaseUrlWithoutVersion,
      swr: { revalidateOnFocus: true },
    },
  );

  const customPageOptions = useMemo(() => {
    const defaultPage = { label: 'Generic 404 Page', value: '' };
    const customPages = customPageSidebar.length
      ? customPageSidebar[0].pages.map(page => ({
          label: page.title,
          value: page.uri,
        }))
      : [];

    return [defaultPage, ...customPages] satisfies SelectOptionProps[];
  }, [customPageSidebar]);

  useConfirmBeforeUnload(isDirty);

  return (
    <Page>
      <PayGate access={formGate === 'plan' ? 'locked' : 'open'} feature="error-pages">
        <PageContent>
          <Fieldset>
            <FormRow columns={1}>
              <RHFGroup
                control={control}
                description={
                  <span>
                    Pick a custom page to forward to. If you don&apos;t have any custom pages,{' '}
                    <a
                      href="/create/page"
                      onClick={event => {
                        event.preventDefault();
                        browserRouterHistory.push('/create/page');
                      }}
                    >
                      create one first
                    </a>
                    .
                  </span>
                }
                id={uid('pages-not-found')}
                label="404 Page"
                name="pages.not_found"
              >
                {({ field }) => (
                  <Select
                    {...field}
                    aria-label="Select 404 Page"
                    onChange={event => field.onChange(event.target.value || null)}
                    options={customPageOptions}
                    size="sm"
                    value={field.value || ''}
                  />
                )}
              </RHFGroup>
            </FormRow>
          </Fieldset>

          <Fieldset className={bem('-redirects')}>
            <FormRow columns={1}>
              {parentSubdomain ? (
                <FormGroup
                  description={
                    <span>
                      Administrators of {`"${parentSubdomain}"`} can manage redirects on the{' '}
                      <a href={dashLinkUrl('errorpages', { toEnterpriseGroup: true })} rel="noreferrer" target="_blank">
                        error pages settings
                        <Icon name="arrow-up-right" size="sm" />
                      </a>
                      .
                    </span>
                  }
                  label="Redirect Rules"
                />
              ) : (
                <RHFGroup
                  control={control}
                  id={uid('redirects')}
                  label={
                    <Flex gap={0} layout="col">
                      Redirect Rules
                      <p className={bem('-redirects-description')}>
                        If there&apos;s no matching ReadMe page, we&apos;ll check it see if there&apos;s a redirect rule
                        for the URL. You can use this to redirect your old non-ReadMe paths to your new site when
                        migrating to ReadMe. Use the format <code>/old/url -&gt; /new/url</code> with one rule per line.{' '}
                        <a href="https://docs.readme.com/docs/error-pages#redirects" rel="noreferrer" target="_blank">
                          Learn More
                        </a>
                      </p>
                    </Flex>
                  }
                  name="redirects"
                >
                  {({ field: { onChange, ...field } }) => (
                    <Textarea
                      {...field}
                      className={bem('-redirects-input')}
                      onBlur={event => {
                        const textValue = event.target.value;
                        const fieldValue = textValue
                          .trim()
                          .replace(/"/g, '')
                          .split('\n')
                          .filter(Boolean)
                          .reduce(
                            (rules, textRow) => {
                              const [from, to] = textRow.split('->').map(text => text.trim());
                              return from && to ? [...rules, { from, to }] : rules;
                            },
                            [] as NonNullable<typeof field.value>,
                          );
                        onChange(fieldValue);
                      }}
                      rows={5}
                      size="md"
                      value={field.value
                        ?.map(redirect => {
                          return redirect.from && redirect.to ? `${redirect.from} -> ${redirect.to}` : '';
                        })
                        .join('\n')
                        .trim()}
                    />
                  )}
                </RHFGroup>
              )}
            </FormRow>
          </Fieldset>
        </PageContent>
      </PayGate>
    </Page>
  );
}

export default ErrorPages;
