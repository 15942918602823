import { capitalize } from '@readme/iso';
import React, { useCallback, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import type { MetricsPageConfig } from '@core/store/Metrics/types';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';
import Toggle from '@ui/Toggle';

import styles from './style.module.scss';

const icons = {
  line: 'icon icon-trending-up',
  bar: 'icon icon-bar-chart-2',
};

interface Props {
  /** Whether filter is being used in Superhub (so dropdown can have dark-on-black styling) */
  isSuperhub?: boolean;
}

const GraphType = ({ isSuperhub }: Props) => {
  const bem = useClassy(styles, 'MetricsFilter-GraphType');

  const [updateQuery, updateMetricsPageConfig, graphQuery, metricsPageConfig] = useMetricsStore(s => [
    s.updateQuery,
    s.updateMetricsPageConfig,
    s.graphQuery,
    s.metricsPageConfig,
  ]);

  const isLineDisabled = metricsPageConfig.endpoint === 'pageQuality';

  const isComparison = useMemo(() => {
    return graphQuery.compare;
  }, [graphQuery]);

  const handleToggleComparison = useCallback(
    e => {
      updateQuery('graphQuery', {
        compare: e.target.checked,
      });
    },
    [updateQuery],
  );

  const handleChangeType = useCallback(
    type => {
      updateMetricsPageConfig({
        ...metricsPageConfig,
        graph: {
          ...metricsPageConfig.graph,
          type,
        },
      } as MetricsPageConfig);
    },
    [metricsPageConfig, updateMetricsPageConfig],
  );

  return (
    <Dropdown align="bottom" className={bem('&')} justify="start" sticky>
      <Button className={bem(isSuperhub && '-btn-dark-on-black')} dropdown kind="secondary" outline size="sm">
        {metricsPageConfig.graph.type in icons && (
          <Flex align="center" gap="xs">
            <i className={icons[metricsPageConfig.graph.type]} />
            <span className="Filter-label">{capitalize(metricsPageConfig.graph.type)}</span>
          </Flex>
        )}
      </Button>
      <Menu>
        <MenuItem
          active={metricsPageConfig.graph.type === 'line'}
          disabled={isLineDisabled}
          icon={icons.line}
          onClick={() => handleChangeType('line')}
        >
          Line Graph
        </MenuItem>
        <MenuItem
          active={metricsPageConfig.graph.type === 'bar'}
          icon={icons.bar}
          onClick={() => handleChangeType('bar')}
        >
          Bar Chart
        </MenuItem>
        <MenuDivider />
        <MenuItem focusable={false}>
          <Flex align="center" justify="between">
            <span>Compare</span>
            <Toggle checked={isComparison} onChange={handleToggleComparison} type="toggle" />
          </Flex>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default GraphType;
