import { useCallback, useContext, useMemo } from 'react';

import type { ConfigContextValue } from '@core/context';
import { BaseUrlContext, ConfigContext } from '@core/context';
import { useProjectStore } from '@core/store';

/**
 * Returns a function that generates a dash link url for the current project.
 * When invoked, the function will return a url base path for either
 * the project's dash or the enterprise group dash that the project belongs to.
 *
 * @example
 * const dashLinkUrl = useDashLinkUrl();
 * dashLinkUrl('appearance') // => /my-project/v2.0/dash?to=appearance
 * dashLinkUrl('translation', { toEnterpriseGroup: true }) // => /group/my-enterprise/translation
 */
export default function useDashLinkUrl() {
  const { domainFull: dashUrl } = useContext(ConfigContext) as ConfigContextValue;
  const baseUrl = useContext(BaseUrlContext);
  const parentSubdomain = useProjectStore(s => s.data.parent);
  const projectBaseUrl = useMemo(() => (baseUrl === '/' ? '' : baseUrl), [baseUrl]);

  const func = useCallback(
    (to: string, { toEnterpriseGroup = false } = {}) => {
      if (toEnterpriseGroup) {
        if (!parentSubdomain) {
          // eslint-disable-next-line no-console
          console.warn('useDashLinkUrl: the current project does not have a parent');
          return undefined;
        }
        return `${dashUrl}/group/${parentSubdomain}/${to}`;
      }
      return `${projectBaseUrl}/dash?to=${to}`;
    },
    [dashUrl, parentSubdomain, projectBaseUrl],
  );

  return func;
}
