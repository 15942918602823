import type { $TSFixMe } from '@readme/iso';

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type { ConfigContextValue, UserContextValue } from '@core/context';
import { BaseUrlContext, ConfigContext, UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDashLinkUrl from '@core/hooks/useDashLinkUrl';
import { useProjectStore, useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import Truncate from '@ui/Truncate';

import ProjectNavigationButton from '../Button';

import classes from './index.module.scss';

interface ProjectMenuProps {
  avatarUrl?: string | null;
  dashUrl: string;
  enterpriseGroupName?: string | null;
  enterpriseGroupUrl?: string | null;
  godmodeUrl?: string | null;
  logoutUrl: string;
  projectName: string;
}

export function ProjectMenu({
  avatarUrl,
  dashUrl,
  enterpriseGroupName,
  enterpriseGroupUrl,
  godmodeUrl,
  logoutUrl,
  projectName,
}: ProjectMenuProps) {
  const bem = useClassy(classes, 'ProjectMenu');
  const subdomain = useProjectStore(s => s.data.subdomain);
  const isEnterpriseGLP = useSuperHubStore(s => !!s.enterprise?.isGroup && s.routeSection === 'home');

  const showGroupLink = !!enterpriseGroupName && !!enterpriseGroupUrl;

  return (
    <Menu data-color-mode="dark" theme="dark">
      <div className={bem('&-header')}>
        <Flex align="center" className={bem('&-header-project')} gap="xs" layout="col">
          {!!avatarUrl && <img alt={`${projectName} logo`} className={bem('&-avatar')} src={avatarUrl} />}
          <span className={bem('&-header-name')}>{projectName}</span>
          {!!enterpriseGroupName && <span className={bem('&-header-label')}>Enterprise</span>}
        </Flex>
        {!!(showGroupLink || godmodeUrl) && (
          <Flex align="center" gap="sm" layout="col">
            {!!showGroupLink && (
              <a className={bem('&-group-link')} href={enterpriseGroupUrl} rel="noreferrer" target="_blank">
                <span className={bem('&-group-link-eyebrow')}>Belongs to</span>
                <div className={bem('&-group-link-name')}>
                  <Truncate>{enterpriseGroupName}</Truncate>
                  <Icon aria-label="Opens in a new tab" className={bem('&-group-link-icon')} name="arrow-up-right" />
                </div>
              </a>
            )}

            {!!godmodeUrl && (
              <Button
                circular
                className={bem('&-godmode-button')}
                href={godmodeUrl}
                size="xs"
                target="_blank"
                uppercase={false}
              >
                <Icon aria-hidden="true" name="zap" />
                God Mode Settings
              </Button>
            )}
          </Flex>
        )}
      </div>

      <MenuDivider />

      {isEnterpriseGLP ? (
        <MenuItem
          href={`${dashUrl}/group/${subdomain}/settings`}
          icon={<Icon aria-hidden="true" name="settings" />}
          TagName="a"
          target="_blank"
        >
          <span>Enterprise Dashboard</span>
        </MenuItem>
      ) : (
        <MenuItem
          icon={<Icon aria-hidden="true" name="settings" />}
          onClick={() => {
            window.location.hash = '/configure/project-settings';
          }}
        >
          Settings
        </MenuItem>
      )}

      <MenuDivider />

      <MenuItem href={dashUrl} icon={<Icon aria-hidden="true" name="grid" />} TagName="a" target="_blank">
        <span>My Projects</span>
      </MenuItem>
      <MenuItem
        href={`${dashUrl}/user/edit`}
        icon={<Icon aria-hidden="true" name="user" />}
        TagName="a"
        target="_blank"
      >
        <span>My Account</span>
      </MenuItem>
      <MenuItem href={logoutUrl} icon={<Icon aria-hidden="true" name="log-out" />} TagName="a">
        Log Out
      </MenuItem>
    </Menu>
  );
}

export default function ProjectMenuDropdown() {
  const bem = useClassy(classes, 'ProjectMenuDropdown');
  const baseUrl = useContext(BaseUrlContext);
  const { domainFull: dashUrl } = useContext(ConfigContext) as ConfigContextValue;
  const { isGod } = useContext(UserContext) as $TSFixMe as UserContextValue;
  const [name, logo, favicon, parentSubdomain] = useProjectStore(s => [
    s.data.name,
    s.data.appearance.logo.main,
    s.data.appearance.logo.favicon,
    s.data.parent,
  ]);
  const isEnterprise = useSuperHubStore(s => !!s.enterprise);
  const { pathname } = useLocation();

  const avatarUrl = favicon.url ? favicon.url : logo.url;
  const cleanBaseUrl = baseUrl === '/' ? '' : baseUrl;

  const dashLinkURL = useDashLinkUrl();

  const logoutUrl = `/logout?redirect_uri=${cleanBaseUrl}${pathname}`;
  const godmodeUrl = isGod ? dashLinkURL('settings') : undefined;
  const enterpriseGroupUrl = isEnterprise ? dashLinkURL('settings', { toEnterpriseGroup: true }) : undefined;

  return (
    <Dropdown className={bem('&')} clickInToClose justify="start">
      <ProjectNavigationButton>
        <Icon aria-hidden="true" name="more-vertical" size="sm" />
        <Truncate>{name}</Truncate>
      </ProjectNavigationButton>
      <ProjectMenu
        avatarUrl={avatarUrl}
        dashUrl={dashUrl}
        enterpriseGroupName={parentSubdomain}
        enterpriseGroupUrl={enterpriseGroupUrl}
        godmodeUrl={godmodeUrl}
        logoutUrl={logoutUrl}
        projectName={name}
      />
    </Dropdown>
  );
}
