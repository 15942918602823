import type { PlanFeatureKey } from '@readme/iso';

import { getFeaturePlan } from '@readme/iso';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useSuperHubStore } from '@core/store';

import PlanAccessBadge from '@routes/SuperHub/components/PlanAccess/Badge';

import Badge from '@ui/Badge';
import Icon from '@ui/Icon';
import SmartLink from '@ui/SmartLink';

import styles from './index.module.scss';

export interface SidebarNavLinkProps {
  className?: string;
  href?: string;
  /**
   * Renders an icon from [Icons library](#/Foundations/Icons).
   */
  icon: string;
  /**
   * Renders an beta badge.
   */
  isBeta?: boolean;
  /**
   * Link label to display.
   */
  label: string;
  /**
   * Plan feature key to check if the user has access to this feature.
   */
  planFeature?: PlanFeatureKey;
  /**
   * Specifies where to open the linked document. Only applies when `href` is
   * provided.
   */
  target?: '_blank' | '_self';
  /**
   * Renders a `NavLink` that automatically navigates to this route. Requires a
   * ReactRouter to be present such as `BrowserRouter` or `HashRouter`.
   */
  to?: string;
}

/**
 * Renders an anchor element that is a standard link with `href` attribute or a
 * ReactRouter `NavLink` with `to` prop. To use the latter, a router must be
 * present somewhere up the component tree.
 */
export function SidebarNavLink({
  className,
  href,
  icon,
  isBeta = false,
  label,
  target = '_self',
  planFeature,
  to,
}: SidebarNavLinkProps) {
  const bem = useClassy(styles, 'SidebarNavLink');
  const isBidiSync = useSuperHubStore(s => s.isBidiSync);
  const { planFeatures, showTrialIndicator } = useProjectPlan();

  if (to === '/configure/git-connection' && !isBidiSync) {
    return null;
  }

  const showPlanBadge = !!planFeature;

  return (
    <li className={bem('&', className)}>
      <SmartLink activeClassName={bem('-link_active')} className={bem('-link')} href={href} target={target} to={to}>
        <Icon className={bem('-icon')} name={icon} size="sm" />
        <span className={bem('-label')}>
          {label}{' '}
          {!!href && target === '_blank' && <Icon className={bem('-external-icon')} name="arrow-up-right" size="sm" />}
        </span>

        {!!isBeta && (
          <Badge className={bem('-badge')} kind="alert">
            Beta
          </Badge>
        )}
        {!!showPlanBadge && (
          <span className={bem('-badge')}>
            <PlanAccessBadge
              access={showTrialIndicator(planFeature) ? 'trial' : planFeatures[planFeature] ? 'open' : 'locked'}
              align="bottom"
              iconOnly
              justify="center"
              plan={getFeaturePlan(planFeature)}
            />
          </span>
        )}
      </SmartLink>
    </li>
  );
}
