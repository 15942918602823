import type { ComponentProps } from 'react';

import React from 'react';

import type { APIErrorType } from '@core/types';

import Flex from '@ui/Flex';
import Notification from '@ui/Notification';

export interface VersionNotificationProps extends ComponentProps<typeof Notification> {
  /**
   * When provided, API v2 error details are parsed and included in the
   * notification description.
   */
  apiError?: APIErrorType;
}

export default function VersionNotification({ apiError, description, ...rest }: VersionNotificationProps) {
  return (
    <Notification
      description={
        <Flex gap={3} layout="col">
          {apiError?.info?.errors?.map(error => (
            <span key={error.key}>{error.message.endsWith('.') ? error.message : `${error.message}.`}</span>
          ))}

          <span>{description}</span>
        </Flex>
      }
      theme="dark"
      {...rest}
    />
  );
}
