import { createContext, useContext } from 'react';

interface RenameVersionModalContextValue {
  isRenameModalOpen: boolean;
  setIsRenameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Contains state that controls whether the edit form's rename modal is
 * currently open or not.
 */
const RenameVersionModalContext = createContext<RenameVersionModalContextValue | null>(null);
RenameVersionModalContext.displayName = 'RenameVersionModalContext';

export default RenameVersionModalContext;

/**
 * Contains state that controls whether the rename version modal is
 * currently open or not.
 */
export const useRenameVersionModalContext = () => {
  const value = useContext(RenameVersionModalContext);
  if (!value) throw new Error('RenameVersionModalContext is not provided');
  return value;
};
