import React from 'react';

import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import LazyTippy from '@ui/LazyTippy';

import CreateVersionFormProvider, { useCreateVersionFormContext } from './Context';
import CreateVersionForm from './Form';

function Content() {
  const [ephemeralVersion, updateEphemeralVersion] = useSuperHubStore(s => [
    s.versions.ephemeralVersion,
    s.versions.updateEphemeralVersion,
  ]);
  const formValue = useCreateVersionFormContext();

  return (
    <LazyTippy
      appendTo={document.body}
      arrow={false}
      content={<CreateVersionForm />}
      interactive
      maxWidth={320}
      offset={[0, 10]}
      onClickOutside={() => {
        if (formValue.formState.isSubmitting) return;
        updateEphemeralVersion(null);
      }}
      placement="top-start"
      role="dialog"
      visible={!!ephemeralVersion}
      zIndex={10002} // Must be less than our Modal z-index.
    >
      <Button
        aria-label="Create New Version"
        kind="secondary"
        onClick={() => updateEphemeralVersion()}
        outline
        size="xs"
      >
        <Icon aria-hidden="true" name="plus" />
        New Version
      </Button>
    </LazyTippy>
  );
}

/**
 * Renders the CTA to create a new version that opens up a form inside a
 * popover. When creation is successful, popover is hidden. If an error occurs,
 * popover remains open so the form can be resubmitted if needed.
 */
export default function CreateVersion() {
  return (
    <CreateVersionFormProvider>
      <Content />
    </CreateVersionFormProvider>
  );
}
