import React from 'react';

import useClassy from '@core/hooks/useClassy';
import type { InitializeSuperHubVersionsProps } from '@core/store';
import { InitializeSuperHubVersions } from '@core/store';

import BasePanel from '@routes/SuperHub/components/BasePanel';

import CreateVersion from './CreateVersion';
import styles from './index.module.scss';
import VersionsList from './List';

interface VersionsPanelProps {
  initialVersions?: InitializeSuperHubVersionsProps['initialVersions'];
}

export default function VersionsPanel({ initialVersions }: VersionsPanelProps) {
  const bem = useClassy(styles, 'VersionsPanel');

  return (
    <InitializeSuperHubVersions initialVersions={initialVersions} revalidateOnFocus>
      <BasePanel label="Manage versions">
        <div className={bem('&')}>
          <VersionsList className={bem('-list')} />

          <footer className={bem('-footer')}>
            <CreateVersion />
          </footer>
        </div>
      </BasePanel>
    </InitializeSuperHubVersions>
  );
}
