import type { ComponentProps } from 'react';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import Input from '@ui/Input';

import styles from './index.module.scss';

type Props = ComponentProps<typeof Input>;

/**
 * A lightweight wrapper around `@ui/Input` that provides some default behavior
 * and styling for entering a semantic version value. For example, prefixing "v"
 * appropriately and disabling auto complete.
 */
const SemVerInput = forwardRef<HTMLInputElement, Props>(function SemVerInput({ className, ...rest }: Props, ref) {
  const bem = useClassy(styles, 'SemVerInput');

  return (
    <Input
      ref={ref}
      autoComplete="off"
      className={bem('&', className)}
      placeholder="2.0"
      prefix="v"
      size="sm"
      {...rest}
    />
  );
});

export default SemVerInput;
