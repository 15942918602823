import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import { findSidebarFirstPage } from '@readme/iso';
import { useCallback, useMemo } from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';

export default function useRedirectToFirstReferenceEndpoint() {
  const { browserRouterLocation, browserRouterHistory } = useSuperHubContext();
  const [apiBaseUrl, defaultApiOptions, isReferenceSection, storeSidebarData] = useSuperHubStore(s => [
    s.apiBaseUrl,
    s.defaultApiOptions,
    s.routeSection === 'reference',
    s.sidebar.data,
  ]);

  const { data: fetchedSidebarData = [] } = useReadmeApi<GitSidebarCategory[]>(
    `${apiBaseUrl}/sidebar?page_type=reference`,
    defaultApiOptions,
    // only fetch data if we're outside of the reference section without access
    // to the reference sidebar data via the store
    !isReferenceSection,
  );

  const referenceSidebarData = useMemo(() => {
    // if we are already in the reference section, we have the sidebar data via
    // the store. if not, we fetched the reference sidebar data via
    // `useReadmeApi` above.
    return isReferenceSection ? storeSidebarData : fetchedSidebarData;
  }, [fetchedSidebarData, isReferenceSection, storeSidebarData]);

  const firstReferenceEndpointRoute = useMemo(() => {
    // keep the user in view or edit mode based on their current location
    const referenceRoute = browserRouterLocation.pathname.includes('/update') ? '/update/reference' : '/reference';
    const firstEndpointPage = findSidebarFirstPage(referenceSidebarData, { pageTypes: ['endpoint'] });

    return `${referenceRoute}/${firstEndpointPage?.slug || ''}`;
  }, [browserRouterLocation.pathname, referenceSidebarData]);

  return useCallback(() => {
    browserRouterHistory.push(firstReferenceEndpointRoute);
  }, [browserRouterHistory, firstReferenceEndpointRoute]);
}
