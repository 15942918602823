import { getFeaturePlan } from '@readme/iso';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';

import PlanAccessBadge from '@routes/SuperHub/components/PlanAccess/Badge';
import AppearanceNavigationMenu, {
  appearanceNavigationItems,
} from '@routes/SuperHub/Settings/Appearance/NavigationMenu';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './index.module.scss';

interface AppearanceNavigationDropdownProps {
  section: string;
}

/**
 * A Dropdown that allows the user to navigate between different
 * appearance settings. Used as a header in each appearance settings form.
 */
export default function AppearanceNavigationDropdown({ section }: AppearanceNavigationDropdownProps) {
  const bem = useClassy(classes, 'AppearanceNavigationDropdown');

  const { planFeatures, showTrialIndicator } = useProjectPlan();

  const currentRoute = appearanceNavigationItems.find(route => route.routeSection === section);
  if (!currentRoute) {
    return null;
  }

  const { planFeature, label, icon } = currentRoute;
  const featureAccessPermission = planFeature ? planFeatures[planFeature] : false;
  const badgeType = showTrialIndicator(planFeature) ? 'trial' : featureAccessPermission ? 'open' : 'locked';
  const upgradePlan = planFeature ? getFeaturePlan(planFeature) : null;

  return (
    <Dropdown className={bem('&')} clickInToClose fullWidth>
      <Button circular className={bem('&-button')} dropdown fullWidth kind="secondary" size="sm">
        <Flex align="center" gap="xs">
          <Icon color="color-text-minimum" name={icon} />
          {label}
          {!!planFeature && !!upgradePlan && (
            <PlanAccessBadge
              access={badgeType}
              align="bottom"
              className={bem('&-badge')}
              justify="center"
              plan={upgradePlan}
              tooltip={true}
            />
          )}
        </Flex>
      </Button>
      <AppearanceNavigationMenu activeRouteSection={currentRoute.routeSection} className={bem('&-menu')} />
    </Dropdown>
  );
}
