import type { TippyProps } from '@tippyjs/react';

import Tippy from '@tippyjs/react';
import React, { useEffect, useState, useRef, useCallback } from 'react';

import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import useLocalStorage from '@core/hooks/useLocalStorage';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './style.module.scss';

export interface FeaturePopoverProps {
  /**
   * Children to render inside the popover
   */
  children: React.ReactElement;

  /**
   * Optional className for custom styling
   */
  className?: string;

  /**
   * Optional createdAt prop to replace user context
   */
  createdAt?: string;

  /**
   * Description text for the feature
   */
  description: string;

  /**
   * Whether to disable the feature popover
   * @default false
   */
  disabled?: boolean;

  /**
   * Duration in milliseconds before the popover auto-dismisses
   * @default 60000 (1 minute)
   */
  duration?: number;

  /**
   * End date when this feature popover should stop showing
   */
  endDate?: Date;

  /**
   * Optional icon name to show above the title
   * @default 'party-popper'
   */
  icon?: string;

  /**
   * Unique identifier for the feature popover
   */
  id: string;

  /**
   * Visual theme of the popover
   * @default 'light'
   */
  theme?: 'dark' | 'glass' | 'light';

  /**
   * Tippy props to pass to the popover
   */
  tippyProps?: TippyProps;

  /**
   * Title of the feature popover
   */
  title: string;
}

const STORAGE_KEY = 'featurePopovers';
const NEW_USER_THRESHOLD = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const FeaturePopover = ({
  children,
  id,
  title,
  description,
  endDate,
  className,
  duration = 30000,
  theme = 'light',
  createdAt,
  disabled = false,
  tippyProps,
}: FeaturePopoverProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(100);
  const [reactionCounts, setReactionCounts] = useState({
    '❤️': 0,
    '🔥': 0,
    '👍': 0,
  });
  const [multiplier, setMultiplier] = useState(1);
  const [isHovered, setIsHovered] = useState(false);

  const startTimeRef = useRef<number>(0);
  const pausedTimeRef = useRef<number>(0);
  const lastPauseRef = useRef<number>(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const hasTrackedRef = useRef(false);

  const bem = useClassy(styles, 'FeaturePopover');
  const storage = useLocalStorage({ json: true });
  const amplitude = useAmplitude();

  const createFlyingEmoji = useCallback(
    (emoji: string, x: number, y: number) => {
      const baseEmojiCount = 8;
      const emojiCount = Math.min(baseEmojiCount * multiplier, 100);
      const emojis = Array.from({ length: emojiCount }).map(() => {
        const element = document.createElement('div');
        element.textContent = emoji;
        element.className = bem('-emoji-animation');

        // Scale the spread based on multiplier (with a max cap to prevent too much spread)
        const spreadMultiplier = Math.min(multiplier, 8);
        const xSpread = 300 * (spreadMultiplier / 2);
        const ySpread = (150 + 50) * (spreadMultiplier / 2);

        const xOffset = (Math.random() - 0.5) * xSpread;
        const yOffset = -(Math.random() * ySpread + 50);
        const spin = (Math.random() - 0.5) * 360 * (spreadMultiplier / 2);

        element.style.setProperty('--x-offset', `${xOffset}px`);
        element.style.setProperty('--y-offset', `${yOffset}px`);
        element.style.setProperty('--spin', `${spin}deg`);

        // Center and move up by 4px
        element.style.left = `${x - 6}px`;
        element.style.top = `${y - 18}px`;

        return element;
      });

      emojis.forEach(element => {
        document.body.appendChild(element);
        element.addEventListener('animationend', () => {
          document.body.removeChild(element);
        });
      });
    },
    [bem, multiplier],
  );

  const handleMultiplierClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setMultiplier(prev => prev * 2);
  }, []);

  const handleDismiss = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setIsVisible(false);
  };

  const handleReaction = useCallback(
    (emoji: string) => (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      // Get the center of the button
      const rect = (e.target as HTMLElement).getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;

      createFlyingEmoji(emoji, x, y);

      // Update reaction counts first so we can use the new total
      setReactionCounts(prev => {
        const newCounts = {
          ...prev,
          [emoji]: prev[emoji] + 1,
        };

        // Calculate total count for the score popup
        const totalCount = Object.values(newCounts).reduce((a, b) => a + b, 0);

        // Create score popup with total count
        const scoreElement = document.createElement('div');
        scoreElement.className = bem('-score-popup');
        scoreElement.dataset.theme = theme;
        scoreElement.textContent = `+${totalCount * multiplier}`;
        scoreElement.style.left = `${x - 6}px`;
        scoreElement.style.top = `${y - 18}px`;
        document.body.appendChild(scoreElement);
        scoreElement.addEventListener('animationend', () => {
          document.body.removeChild(scoreElement);
        });

        // Only send amplitude event if we haven't tracked yet
        if (!hasTrackedRef.current) {
          // Clear any existing timeout
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          // Schedule new event with 1.5s debounce
          timeoutRef.current = setTimeout(() => {
            const eventData = {
              [AMPLITUDE_EVENT_PROPERTY.FEATURE_ID]: id,
              [AMPLITUDE_EVENT_PROPERTY.FEATURE_TITLE]: title,
              [AMPLITUDE_EVENT_PROPERTY.FEATURE_REACTION]: emoji,
              [AMPLITUDE_EVENT_PROPERTY.FEATURE_REACTION_COUNTS]: totalCount * multiplier,
            };
            amplitude.track(AMPLITUDE_EVENT.FEATURE_FEEDBACK, eventData);
            hasTrackedRef.current = true;
          }, 1500);
        }

        return newCounts;
      });
    },
    [amplitude, id, title, createFlyingEmoji, multiplier, bem, theme],
  );

  useEffect(() => {
    const checkShouldShow = () => {
      // Don't show in development mode
      if (process.env.NODE_ENV === 'development') {
        return false;
      }

      const storedFeatures = storage.getItem(STORAGE_KEY) || {};
      const featureState = storedFeatures[id];

      if (endDate && new Date() > new Date(endDate)) {
        return false;
      }

      if (createdAt) {
        const userCreatedAt = new Date(createdAt).getTime();
        const now = Date.now();

        if (now - userCreatedAt < NEW_USER_THRESHOLD) {
          return false;
        }
      }

      if (!featureState) {
        const updatedFeatures = {
          ...storedFeatures,
          [id]: {
            shownAt: new Date().toISOString(),
          },
        };
        storage.setItem(STORAGE_KEY, updatedFeatures);
        return true;
      }

      return false;
    };

    const shouldShow = checkShouldShow();
    if (shouldShow) {
      startTimeRef.current = Date.now();
      setIsVisible(true);
    }

    return () => {
      // Cleanup function to prevent state updates after unmount
      setIsVisible(false);
    };
  }, [id, endDate, createdAt, storage]);

  useEffect(() => {
    if (!isVisible) {
      return undefined;
    }

    if (isHovered) {
      // Store when we paused
      lastPauseRef.current = Date.now();
      return undefined;
    } else if (lastPauseRef.current) {
      // Add paused duration to total paused time
      pausedTimeRef.current += Date.now() - lastPauseRef.current;
      lastPauseRef.current = 0;
    }

    let animationFrameId: number;

    const updateProgress = () => {
      const now = Date.now();
      const elapsed = now - startTimeRef.current - pausedTimeRef.current;
      const remaining = Math.max(0, duration - elapsed);
      const newProgress = (remaining / duration) * 100;

      if (newProgress <= 0) {
        setProgress(0);
        setIsVisible(false);
      } else {
        setProgress(newProgress);
        animationFrameId = requestAnimationFrame(updateProgress);
      }
    };

    animationFrameId = requestAnimationFrame(updateProgress);

    const cleanup = () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    return cleanup;
  }, [isVisible, duration, isHovered]);

  // Reset refs when popover becomes visible
  useEffect(() => {
    if (isVisible) {
      startTimeRef.current = Date.now();
      pausedTimeRef.current = 0;
      lastPauseRef.current = 0;
    }
  }, [isVisible]);

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      setIsVisible(false);
    }
  };

  // Add cleanup for timeout when component unmounts or becomes invisible
  useEffect(() => {
    if (!isVisible) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      hasTrackedRef.current = false;
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isVisible]);

  if (disabled) {
    return children;
  }

  if (!isVisible) {
    return children;
  }

  const content = (
    <div
      className={bem('-content')}
      onKeyPress={handleKeyPress}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="button"
      tabIndex={0}
    >
      <div className={bem('-header')}>
        <Flex align="center" className={bem('-header-content')} gap={0} justify="between">
          <div className={bem('-spinner')}>
            <div className={bem('-spinner-label')}>
              <svg
                className={bem('-spinner-svg', '-spinner-svg_blue')}
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M289.9 18.4c-18.7-18.7-49.1-18.7-67.9 0L176.5 64 112 64c-26.5 0-48 21.5-48 48l0 64.5L18.4 222.1c-18.7 18.7-18.7 49.1 0 67.9L64 335.5 64 400c0 26.5 21.5 48 48 48l64.5 0 45.6 45.6c18.7 18.7 49.1 18.7 67.9 0L335.5 448l64.5 0c26.5 0 48-21.5 48-48l0-64.5 45.6-45.6c18.7-18.7 18.7-49.1 0-67.9L448 176.5l0-64.5c0-26.5-21.5-48-48-48l-64.5 0L289.9 18.4z" />
              </svg>
              <svg
                className={bem('-spinner-svg', '-spinner-svg_green')}
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M289.9 18.4c-18.7-18.7-49.1-18.7-67.9 0L176.5 64 112 64c-26.5 0-48 21.5-48 48l0 64.5L18.4 222.1c-18.7 18.7-18.7 49.1 0 67.9L64 335.5 64 400c0 26.5 21.5 48 48 48l64.5 0 45.6 45.6c18.7 18.7 49.1 18.7 67.9 0L335.5 448l64.5 0c26.5 0 48-21.5 48-48l0-64.5 45.6-45.6c18.7-18.7 18.7-49.1 0-67.9L448 176.5l0-64.5c0-26.5-21.5-48-48-48l-64.5 0L289.9 18.4z" />
              </svg>
              <svg
                className={bem('-spinner-svg', '-spinner-svg_yellow')}
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M289.9 18.4c-18.7-18.7-49.1-18.7-67.9 0L176.5 64 112 64c-26.5 0-48 21.5-48 48l0 64.5L18.4 222.1c-18.7 18.7-18.7 49.1 0 67.9L64 335.5 64 400c0 26.5 21.5 48 48 48l64.5 0 45.6 45.6c18.7 18.7 49.1 18.7 67.9 0L335.5 448l64.5 0c26.5 0 48-21.5 48-48l0-64.5 45.6-45.6c18.7-18.7 18.7-49.1 0-67.9L448 176.5l0-64.5c0-26.5-21.5-48-48-48l-64.5 0L289.9 18.4z" />
              </svg>
              <svg
                className={bem('-spinner-svg', '-spinner-svg_red')}
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M289.9 18.4c-18.7-18.7-49.1-18.7-67.9 0L176.5 64 112 64c-26.5 0-48 21.5-48 48l0 64.5L18.4 222.1c-18.7 18.7-18.7 49.1 0 67.9L64 335.5 64 400c0 26.5 21.5 48 48 48l64.5 0 45.6 45.6c18.7 18.7 49.1 18.7 67.9 0L335.5 448l64.5 0c26.5 0 48-21.5 48-48l0-64.5 45.6-45.6c18.7-18.7 18.7-49.1 0-67.9L448 176.5l0-64.5c0-26.5-21.5-48-48-48l-64.5 0L289.9 18.4z" />
              </svg>
            </div>
            <div className={bem('-spinner-text')}>NEW</div>
          </div>
          <div className={bem('-close-button-container')}>
            <Button
              className={bem('-close-button')}
              ghost
              kind="contrast"
              onClick={handleDismiss}
              size="xs"
              title="Close"
            >
              <Icon name="x" />
            </Button>
          </div>
        </Flex>
        {!!title && <h3 className={bem('-title')}>{title}</h3>}
      </div>
      {!!description && <p className={bem('-description')}>{description}</p>}
      <div className={bem('-progress')}>
        <div className={bem('-progressBar')} style={{ width: `${progress}%` }} />
      </div>
      <Flex align="center" className={bem('-reaction-buttons')} justify="between">
        <Flex gap={0}>
          <Button
            aria-label="React with Heart"
            className={bem('-like-button')}
            ghost
            kind="contrast"
            onClick={handleReaction('❤️')}
            size="xs"
            title="React with Heart"
          >
            ❤️
          </Button>
          <Button
            aria-label="React with Fire"
            className={bem('-like-button')}
            ghost
            kind="contrast"
            onClick={handleReaction('🔥')}
            size="xs"
            title="React with Fire"
          >
            🔥
          </Button>
          <Button
            aria-label="React with Thumbs Up"
            className={bem('-like-button')}
            ghost
            kind="contrast"
            onClick={handleReaction('👍')}
            size="xs"
            title="React with Thumbs Up"
          >
            👍
          </Button>
        </Flex>
        {Object.values(reactionCounts).reduce((a, b) => a + b, 0) > 0 && (
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
          <span className={bem('-reaction-count')} onClick={handleMultiplierClick} style={{ cursor: 'pointer' }}>
            {Object.values(reactionCounts).reduce((a, b) => a + b, 0)}
            {multiplier > 1 && <span style={{ opacity: 0.7, marginLeft: '2px' }}>x{multiplier}</span>}
          </span>
        )}
      </Flex>
    </div>
  );

  return (
    <Tippy
      animation="scale-subtle"
      arrow={false}
      className={bem(null, className)}
      content={content}
      duration={[200, 400]}
      interactive={true}
      theme={theme}
      visible={isVisible}
      {...tippyProps}
    >
      {children}
    </Tippy>
  );
};

export default FeaturePopover;
