import { useMetricsStore } from '@core/store';

import useApiReferenceMetricsFilters from './useApiReferenceMetricsFilters';

interface ReturnValue {
  /**
   * True if the current filter selections mean we're viewing results for the
   * current API reference page (i.e. the current operation's path and method
   * are selected as filters). Returns false otherwise.
   */
  isApiReferenceFilteredView: boolean;
  /**
   * True if `isApiReferenceFilteredView` and no additional filters are selected
   * (e.g. no additional status, user agent, search terms, etc.) meaning we're
   * on the initial filtered view for the current API reference page.
   */
  isApiReferenceInitialFilteredView: boolean;
}

export default function useIsApiReferenceFilteredView(): ReturnValue {
  const { selectedFilters, changeFromInitialFilters } = useMetricsStore(s => ({
    selectedFilters: s.myDevelopers.filters,
    changeFromInitialFilters: s.myDevelopers.getChangeFromInitialFilters(),
  }));
  const apiReferenceFilters = useApiReferenceMetricsFilters();

  if (!apiReferenceFilters) {
    return { isApiReferenceFilteredView: false, isApiReferenceInitialFilteredView: false };
  }

  // for every filter corresponding to the API reference page (i.e. the path and
  // method for that API), are those what's currently selected in the My
  // Developers filters (i.e. are exactly that path and method selected)?
  const isApiReferenceFilteredView = Object.entries(apiReferenceFilters).every(([key, filterValue]) => {
    const filterKey = key as keyof typeof apiReferenceFilters;

    const selectedValue = selectedFilters[filterKey];
    if (typeof selectedValue === 'string') {
      return selectedValue === filterValue;
    } else if (Array.isArray(selectedValue)) {
      return selectedValue.length === 1 && selectedValue[0] === filterValue;
    }

    return false;
  });

  // if we're in the API reference filtered view, have any other additional
  // filters besides path and method been selected? If not, then it's the
  // initial filtered view
  const changedFiltersKeys = Object.keys(changeFromInitialFilters);
  const isApiReferenceInitialFilteredView =
    isApiReferenceFilteredView &&
    changedFiltersKeys.length === 2 &&
    changedFiltersKeys.includes('path') &&
    changedFiltersKeys.includes('method');

  return { isApiReferenceFilteredView, isApiReferenceInitialFilteredView };
}
