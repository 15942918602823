import type { CreateVersionType } from '@readme/api/src/mappings/version/types';

import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useSuperHubStore } from '@core/store';

interface CreateVersionFormProviderProps {
  children: React.ReactNode;
}

/**
 * Creates a new React Hook Form that loads the initial state for either an
 * existing version or when creating a new one. Renders the form context
 * provider that grants API access to all descendants.
 */
export default function CreateVersionFormProvider({ children }: CreateVersionFormProviderProps) {
  const ephemeralVersion = useSuperHubStore(s => s.versions.ephemeralVersion);

  // Create new version with base version defaulting to the current version.
  const defaultVersion = useMemo(() => {
    return ephemeralVersion || undefined;
  }, [ephemeralVersion]);

  const formValue = useForm<CreateVersionType>({ defaultValues: defaultVersion });

  useEffect(() => {
    formValue.reset(defaultVersion);
  }, [defaultVersion, formValue]);

  return <FormProvider {...formValue}>{children}</FormProvider>;
}

/**
 * Returns the React Hook Form context to be used when creating versions.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useCreateVersionFormContext = () => {
  const value = useFormContext<CreateVersionType>();
  if (!value) throw new Error('CreateVersionFormProvider is not provided');
  return value;
};
