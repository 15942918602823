import { useCallback, useContext } from 'react';
import { matchPath } from 'react-router-dom';

import { LocalizationContext } from '@core/context';
import { useSuperHubStore, useProjectStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';
import { SuperHubRoutePaths } from '@routes/SuperHub/types';

/**
 * Generates a function that when called returns a URL for the provided version
 * name that is based off the current route location path and hash. Use this to
 * redirect users from one version to another. Route slug segments are removed
 * because not every version will have equivalent pages. So we defer to the
 * section and let users be redirected to the first page of that section.
 * @example
 * ```ts
 * // -> /docs/child-page#/appearance/theme-editor
 * const buildVersionPath = useBuildVersionPath();
 * const redirectToVersionUrl = buildVersionPath('2.0');
 * window.location.assign(redirectToVersionUrl);
 * // -> /v2.0/docs#/appearance/theme-editor
 * ```
 */
export default function useBuildVersionPath() {
  const defaultVersion = useProjectStore(s => s.data.default_version.name);
  const isEnterpriseChild = useSuperHubStore(s => s.enterprise?.isChild);
  const subdomain = useProjectStore(s => s.data.subdomain);
  const language = useContext(LocalizationContext);

  const { browserRouterLocation } = useSuperHubContext();
  const { pathname, hash } = browserRouterLocation;
  const match = matchPath<SuperHubRouteParams>(pathname, Object.values(SuperHubRoutePaths));
  const { action: actionSegment, section: sectionSegment } = match?.params ?? {};

  return useCallback(
    (versionName: string) => {
      /**
       * Path construction logic here mirrors the Hub header's version dropdown
       * @see /react/src/Hub/Header/utils/hooks.js
       */
      const subdomainSegment = isEnterpriseChild && subdomain;
      const languageSegment = language !== 'en' && `lang-${language}`;
      const versionSegment = defaultVersion !== versionName && `v${versionName}`;
      const hashSegment = hash || '';

      return `/${[subdomainSegment, languageSegment, versionSegment, actionSegment, sectionSegment]
        .filter(Boolean)
        .join('/')}${hashSegment}`;
    },
    [isEnterpriseChild, subdomain, language, defaultVersion, actionSegment, sectionSegment, hash],
  );
}
