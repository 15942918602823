import { addMonths, format, startOfMonth } from 'date-fns';
import React from 'react';

import Link from '@Dash/Link';

import useClassy from '@core/hooks/useClassy';
import useIntercom from '@core/hooks/useIntercom';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Title from '@ui/Title';

import styles from './style.module.scss';

interface Props {
  isOpen: boolean;
  nearLimit?: boolean;
  overLimit?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  theme?: string;
}

const LogsLimitPopover = ({ overLimit, nearLimit, isOpen, setIsOpen, theme }: Props) => {
  const bem = useClassy(styles, 'LogsLimitPopover');

  const triggerIntercom = useIntercom({
    hideDefaultLauncher: true,
    showNewMessage: "Hello! I need to increase my log limit for My Developers'",
    trackEvent: {
      name: 'metrics-overage-help',
      metadata: {
        type: 'logs',
      },
    },
  });

  // Calculate the first day of the next month
  const firstDayOfNextMonth = format(startOfMonth(addMonths(new Date(), 1)), 'MMMM do');

  let popoverTitle;
  let popoverDescription;

  if (overLimit) {
    popoverTitle = 'Monthly Limit Reached';
    popoverDescription = `We've stopped capturing your log data, it will resume on ${firstDayOfNextMonth}.`;
  } else if (nearLimit) {
    popoverTitle = 'Nearing Monthly Limit';
    popoverDescription = `You have used 75% of your monthly logs limit, it will reset on ${firstDayOfNextMonth}.`;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={bem('&', theme && `-${theme}`)} style={{ display: isOpen ? 'block' : 'none' }}>
      <div className={bem('-content')}>
        <Flex className={bem('-header')} gap="xs" justify="start">
          <div className={bem('-icon')}>
            <Icon name="alert-triangle" size="md" />
          </div>
          <div
            className={bem('-close')}
            onClick={handleClose}
            onKeyDown={e => {
              if (e.key === 'Enter') handleClose();
            }}
            role="button"
            tabIndex={0}
          >
            <Icon className={bem('-close-icon')} color={'gray40'} name={'x'} />
          </div>
          <Flex gap="xs" layout="col">
            <Title className={bem('-header-title')} level={5}>
              {popoverTitle}
            </Title>
            <div className={bem('-description')}>{popoverDescription}</div>
            <Link className={bem('-increase-limit')} onClick={triggerIntercom} to={'#'}>
              Request Increase
            </Link>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default LogsLimitPopover;
