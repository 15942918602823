import * as dateFns from 'date-fns';

const scalingFactor = (seed, factor) => Math.round((seed || 1) * `${factor || 1000}`);

const resolutionFormat = {
  hour: 'hh:mm bbb',
  day: 'MMM d',
  week: 'MMM d',
  month: 'MMMM',
  year: 'YYYY',
};

export const resolutionFunctions = {
  hour: {
    subtract: dateFns.subHours,
    toStart: dateFns.startOfHour,
  },
  day: {
    subtract: dateFns.subDays,
    toStart: dateFns.startOfDay,
  },
  week: {
    subtract: dateFns.subWeeks,
    toStart: dateFns.startOfWeek,
  },
  month: {
    subtract: dateFns.subMonths,
    toStart: dateFns.startOfMonth,
  },
  year: {
    subtract: dateFns.subYears,
    toStart: dateFns.startOfYear,
  },
};

const generateRandomData = (rangeLength = 30) =>
  Array.from(new Array(rangeLength)).map((i, idx) => scalingFactor(idx % 4, idx + rangeLength / 2));

const generateDateRangeLabels = (rangeLength = 30, resolution = 'day') => {
  const dateFormat = resolutionFormat[resolution];
  const { subtract, toStart } = resolutionFunctions[resolution];

  const dec4 = new Date(Date.parse('04 Dec 2024 00:10:00 GMT'));

  return Array.from(new Array(rangeLength)).map((i, idx) => {
    if (idx === rangeLength - 1) return 'Now';

    const date = subtract(toStart(dec4), rangeLength - (idx + 1));
    return dateFns.format(date, dateFormat);
  });
};

const dataLg = [
  scalingFactor(3),
  scalingFactor(10),
  scalingFactor(7),
  scalingFactor(3),
  scalingFactor(4),
  scalingFactor(7),
  scalingFactor(6),
];

const dataMd = [
  scalingFactor(1, 100),
  scalingFactor(5, 100),
  scalingFactor(7, 100),
  scalingFactor(4, 100),
  scalingFactor(10, 100),
  scalingFactor(2, 100),
  scalingFactor(11, 100),
];

const dataMulti = {
  datasets: [
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: 'rgba(17,140,253,1)',
      data: dataLg,
    },
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#12ca93',
      data: [
        scalingFactor(3),
        scalingFactor(10),
        scalingFactor(4, 100),
        scalingFactor(12, 100),
        scalingFactor(8),
        scalingFactor(7),
        scalingFactor(6),
      ],
    },
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#f7c543',
      data: dataMd,
    },
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#e95f6a',
      data: [
        scalingFactor(9, 100),
        scalingFactor(11, 10),
        scalingFactor(4, 10),
        scalingFactor(5, 10),
        scalingFactor(8, 10),
        scalingFactor(10, 10),
        scalingFactor(68, 100),
      ],
    },
  ],
};

export const dataMultiSm = {
  ...dataMulti,
  labels: ['June 23', '', '', '', '', '', 'Now'],
};

const singleAggDefault = {
  lineTension: 0,
  backgroundColor: 'transparent',
  borderColor: 'rgba(198,203,211,1)',
  data: [],
};

export const dataSingleDemo = (rangeLength = 30, resolution = 'day') => {
  const singleDemoData = generateRandomData(rangeLength);
  const labels = generateDateRangeLabels(rangeLength, resolution);
  return { datasets: [{ ...singleAggDefault, data: singleDemoData }], labels };
};

const multiAggDefaultDataSets = [
  {
    lineTension: 0,
    backgroundColor: 'transparent',
    borderColor: 'rgba(240,240,240,1)',
    data: [],
  },
  {
    lineTension: 0,
    backgroundColor: 'transparent',
    borderColor: 'rgba(198,203,211,1)',
    data: [],
  },
];

export const dataMultiDemo = (rangeLength = 30, resolution = 'day') => {
  const labels = generateDateRangeLabels(rangeLength, resolution);
  return { datasets: multiAggDefaultDataSets.map(set => ({ ...set, data: generateRandomData(rangeLength) })), labels };
};

const errorAggDefaultDataSets = [
  {
    lineTension: 0,
    backgroundColor: 'rgba(0,0,0,.05)',
    borderColor: 'rgba(0,0,0,0)',
    data: [90, 93, 93, 97, 94, 92, 97],
  },
  {
    lineTension: 0,
    backgroundColor: 'transparent',
    borderColor: 'rgba(240,240,240,1)',
    data: [12, 13, 15, 27, 80, 16, 17],
  },
  {
    lineTension: 0,
    backgroundColor: 'transparent',
    borderColor: 'rgba(198,203,211,1)',
    data: [2, 3, 5, 17, 55, 6, 7],
  },
  {
    lineTension: 0,
    backgroundColor: 'transparent',
    borderColor: 'rgba(173,180,193,1)',
    data: [0, 0, 0, 1, 15, 0, 0],
  },
];

export const dataErrorsDemo = (rangeLength = 30, resolution = 'day') => {
  const labels = generateDateRangeLabels(rangeLength, resolution);
  return { datasets: errorAggDefaultDataSets.map(set => ({ ...set, data: generateRandomData(rangeLength) })), labels };
};

export const dataMultiLg = {
  ...dataMulti,
  labels: ['June 23', 'June 24', 'June 25', 'June 26', 'June 27', 'June 28', 'Now'],
};

const dataSingle = {
  datasets: [
    {
      lineTension: 0,
      backgroundColor: 'rgba(17,140,253,.0)',
      borderColor: 'rgba(17,140,253,1)',
      data: [
        scalingFactor(4, 100),
        scalingFactor(11, 100),
        scalingFactor(12, 100),
        scalingFactor(6, 100),
        scalingFactor(9, 100),
        scalingFactor(10, 100),
        scalingFactor(2, 100),
      ],
    },
  ],
};

export const dataSingleLg = {
  ...dataSingle,
  labels: ['June 23', 'June 24', 'June 25', 'June 26', 'June 27', 'June 28', 'Now'],
};

export const dataSingleSm = {
  ...dataSingle,
  labels: ['June 23', '', '', '', '', '', 'June 29'],
};

export const dataMonth = {
  datasets: [
    {
      lineTension: 0,
      backgroundColor: 'rgba(17,140,253,.0)',
      borderColor: 'rgba(17,140,253,1)',
      data: [
        scalingFactor(5),
        scalingFactor(8),
        scalingFactor(9),
        scalingFactor(12),
        scalingFactor(13),
        scalingFactor(5),
        scalingFactor(9),
        scalingFactor(1),
        scalingFactor(3),
        scalingFactor(16),
        scalingFactor(4),
        scalingFactor(9),
        scalingFactor(3),
        scalingFactor(13),
        scalingFactor(14),
        scalingFactor(6),
        scalingFactor(7),
        scalingFactor(3),
        scalingFactor(2),
        scalingFactor(10),
        scalingFactor(9),
        scalingFactor(4),
        scalingFactor(9),
        scalingFactor(2),
        scalingFactor(12),
        scalingFactor(10),
        scalingFactor(8),
        scalingFactor(7),
      ],
    },
  ],
  labels: [
    'June 23',
    'June 24',
    'June 25',
    'June 26',
    'June 27',
    'June 28',
    'June 29',
    'June 30',
    'July 1',
    'July 2',
    'July 3',
    'July 4',
    'July 5',
    'July 6',
    'July 7',
    'July 8',
    'July 9',
    'July 10',
    'July 11',
    'July 12',
    'July 13',
    'July 14',
    'July 15',
    'July 16',
    'July 17',
    'July 18',
    'July 19',
    'July 20',
  ],
};

const dataErrors = {
  datasets: [
    {
      lineTension: 0,
      backgroundColor: 'rgba(0,0,0,.05)',
      borderColor: 'rgba(0,0,0,0)',
      data: [90, 93, 93, 97, 94, 92, 97],
    },
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: 'rgba(233,95,106,1)',
      data: dataMd,
    },
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#e9755e',
      data: [
        scalingFactor(4, 100),
        scalingFactor(8, 100),
        scalingFactor(9, 10),
        scalingFactor(10, 10),
        scalingFactor(6, 10),
        scalingFactor(6, 10),
        scalingFactor(3, 10),
      ],
    },
    {
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#e9a35e',
      data: [
        scalingFactor(5, 100),
        scalingFactor(10, 10),
        scalingFactor(8, 10),
        scalingFactor(7, 10),
        scalingFactor(6, 10),
        scalingFactor(1, 10),
        scalingFactor(5, 100),
      ],
    },
  ],
};

export const dataErrorsSm = {
  ...dataErrors,
  labels: ['June 23', '', '', '', '', '', 'Now'],
};

export const dataErrorsLg = {
  ...dataErrors,
  labels: ['June 23', 'June 24', 'June 25', 'June 26', 'June 27', 'June 28', 'Now'],
};

const dataQuality = {
  datasets: [
    {
      data: dataMd,
      backgroundColor: '#12ca93',
    },
    {
      data: [
        scalingFactor(4, 10) * -1,
        scalingFactor(3, 10) * -1,
        scalingFactor(7, 10) * -1,
        scalingFactor(5, 10) * -1,
        scalingFactor(3, 10) * -1,
        scalingFactor(3, 10) * -1,
        scalingFactor(9, 10) * -1,
      ],
      backgroundColor: '#e95f6a',
    },
  ],
};

export const dataQualitySm = {
  ...dataQuality,
  labels: ['June 23', '', '', '', '', '', 'Now'],
};

export const dataQualityLg = {
  ...dataQuality,
  labels: ['June 23', 'June 24', 'June 25', 'June 26', 'June 27', 'June 28', 'Now'],
};

const baseOpts = {
  animation: false,
  elements: {
    line: { fill: true },
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
      usePointStyle: true,
      mode: 'interpolate',
      intersect: false,
    },
    crosshair: {},
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        drawBorder: 0,
      },
      ticks: {
        color: '#637288',
        autoSkip: true,
        autoSkipPadding: 50,
        maxRotation: 0,
      },
    },
    y: {
      grid: {
        drawBorder: 0,
      },
      ticks: {
        color: '#637288',
      },
    },
  },
};

const smallOpts = {
  maintainAspectRatio: false,
  responsive: false,
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        maxTicksLimit: 3,
      },
    },
    y: {
      ticks: {
        maxTicksLimit: 6,
      },
    },
  },
};

export const options = {
  ...baseOpts,
};

export const optionsSm = {
  ...baseOpts,
  ...smallOpts,
};

const optionsStackedBar = {
  ...baseOpts,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      grid: {
        drawBorder: 0,
      },
      stacked: true,
      ticks: {
        maxTicksLimit: 6,
      },
    },
  },
  type: 'bar',
};

export const optionsStackedBarSm = {
  ...optionsStackedBar,
  ...smallOpts,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        maxTicksLimit: 6,
      },
    },
  },
  type: 'bar',
};

export const optionsStackedBarLg = {
  ...optionsStackedBar,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  type: 'bar',
};

// Used in MetricsFallback for LineGraph w/ demo data
export const demoDataOptions = (isCondensed = false) => {
  return {
    animation: false,
    aspectRatio: isCondensed ? 3 : 2,
    clip: false,
    hover: {
      mode: 'index',
      intersect: false,
    },
    layout: {
      padding: 0,
    },
    plugins: {
      tooltip: {
        axis: 'x',
        mode: 'index',
        intersect: false,
        enabled: false,
        xAlign: 'left',
        yAlign: 'center',
      },
      crosshair: {},
    },
    scales: {
      x: {
        display: true,
        grid: {
          drawBorder: 0,
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 50,
          color: '#637288',
          maxRotation: 0,
          maxTicksLimit: isCondensed ? 3 : undefined,
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        grid: {
          drawBorder: 0,
        },
        ticks: {
          color: '#637288',
          count: isCondensed ? 3 : undefined,
        },
      },
    },
  };
};
