import { ValidSortParams } from '@readme/api/src/mappings/customblock/constants';
import React, { useCallback, useContext } from 'react';

import { Link } from '@Dash/Link';

import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';

import { useCustomBlockMgmtContext } from '../Context';

import classes from './style.module.scss';

const Filter = () => {
  const bem = useClassy(classes, 'Filter');
  const { project } = useContext(ProjectContext);
  const isGroup = project?.childrenProjects?.length;
  const { filter, setFilter, type } = useCustomBlockMgmtContext();

  const updateSearch = useDebounced((value: string) => {
    setFilter({ search: value, sort: filter.sort });
  }, 300);

  const handleSearchChange = useCallback(
    e => {
      const { value } = e.target;
      updateSearch(value.trim());
    },
    [updateSearch],
  );

  const updateSort = (value: string) => {
    setFilter({ search: filter.search, sort: value });
  };

  const placeholder = type
    ? `Search ${type.charAt(0).toUpperCase() + type.slice(1)}${type === 'component' ? 's' : ''}` // Convert type to title case Content or Components
    : 'Search';

  return (
    <div className={bem()}>
      <Input
        onChange={handleSearchChange}
        onClear={() => updateSearch('')}
        placeholder={placeholder}
        prefix={<Icon name="search" />}
        size="sm"
        value={filter.search}
      />
      <Dropdown sticky>
        <Button kind="secondary" outline size="sm">
          <Icon name="sliders" />
        </Button>
        <Menu>
          <MenuHeader>Sort</MenuHeader>
          <MenuItem
            active={filter.sort === ValidSortParams.UPDATED_AT}
            icon={<Icon name="clock" />}
            onClick={() => updateSort(ValidSortParams.UPDATED_AT)}
          >
            Last Edited
          </MenuItem>
          <MenuItem
            active={filter.sort === 'sort_asc'}
            icon={<Icon name="a-to-z" />}
            onClick={() => updateSort('sort_asc')}
          >
            A to Z
          </MenuItem>
          <MenuItem
            active={filter.sort === 'sort_desc'}
            icon={<Icon name="z-to-a" />}
            onClick={() => updateSort('sort_desc')}
          >
            Z to A
          </MenuItem>
          {/* TODO: filter by project for enterprise */}
          {!!isGroup && !isGroup && (
            <>
              <MenuDivider />
              <MenuHeader>Project Filter</MenuHeader>
            </>
          )}
        </Menu>
      </Dropdown>
      <Button
        aria-label={type === 'content' ? 'Add New Reusable Content' : 'Add a New Custom Component'}
        // @ts-ignore
        is={Link}
        outline
        size="sm"
        to="new"
      >
        <Icon name="plus" strokeWeight={3} />
      </Button>
    </div>
  );
};

export default Filter;
