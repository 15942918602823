import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import { format } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import DemoGate from '@routes/SuperHub/components/PlanAccess/DemoGate';
import { FormRow, Page, PageContent } from '@routes/SuperHub/Settings/components';

import type { CodeInputProps } from '@ui/CodeInput';
import CodeInput from '@ui/CodeInput';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import type { ImageUploaderProps } from '@ui/ImageUploader';
import ImageUploader from '@ui/ImageUploader';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';

import { useProjectSettingsFormContext, useFormGateContext } from '../Context';
import useLiveUpdateProjectStore from '../useLiveUpdateProjectStore';

import BestPractices from './BestPractices';
import classes from './style.module.scss';

const fields: Path<ProjectSettingsFormValues>[] = [
  'appearance.custom_code.css',
  'appearance.markdown.callouts.icon_font',
];

function CustomCSS() {
  const uid = useUniqueId('CustomCSS');
  const bem = useClassy(classes, 'CustomCode');

  const { control, getValues, setValue } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();

  const [showImageSuccess, setShowImageSuccess] = useState(false);
  const codeEditorRef: CodeInputProps['codeEditorRef'] = useRef(null);

  useLiveUpdateProjectStore({ fields, control });

  const calloutIcons = useWatch({ control, name: 'appearance.markdown.callouts.icon_font' });

  /**
   * Handles CSS image upload data and injects image metadata into the editor.
   * Users can then copy/paste the source URL into their CSS as needed.
   */
  const handleImageUpload = useCallback<NonNullable<ImageUploaderProps['onFinish']>>(
    data => {
      if (!data?.url) return null;

      // Add image metadata to the custom css editor at the very top.
      setValue(
        'appearance.custom_code.css',
        [
          '/*',
          `(Hosted Image | ${format(new Date(), 'yyyy/MM/dd kk:mm:ss')} | ${data?.width} x ${data?.height})`,
          data.url,
          '*/',
          getValues('appearance.custom_code.css'),
        ].join('\n'),
      );

      // Temporarily reveal the image upload success state, but hide it after a
      // few seconds so new success states can be triggered from new uploads.
      setShowImageSuccess(true);
      const timeoutId = window.setTimeout(() => setShowImageSuccess(false), 6000);
      return () => window.clearTimeout(timeoutId);
    },
    [getValues, setValue],
  );

  return (
    <Page>
      <PageContent isCondensed>
        <DemoGate access={formGate === 'demo' ? 'locked' : 'open'} feature="css">
          <BestPractices type="css" />
          <FormRow columns={1} fullWidth>
            <RHFGroup
              control={control}
              description="Note: CSS is not versioned. All versions of a project use the same custom CSS."
              id={uid('css')}
              name="appearance.custom_code.css"
            >
              {({ field }) => (
                <>
                  <div className={bem('-code-input-container')}>
                    <CodeInput
                      {...field}
                      className={bem('-code-input')}
                      codeEditorRef={codeEditorRef}
                      initialValue={field.value}
                      isDarkMode
                      language="css"
                      size="md"
                    />
                    <Flex
                      align="center"
                      className={bem('-image-upload-success')}
                      gap="xs"
                      hidden={!showImageSuccess}
                      justify="start"
                    >
                      <Icon color="green50" name="check-circle" />
                      <span>
                        Image upload successful and
                        <button
                          className={bem('-image-upload-scroll')}
                          onClick={() => {
                            codeEditorRef.current?.getScrollerElement().scroll({
                              behavior: 'smooth',
                              top: 0,
                              left: 0,
                            });
                          }}
                          type="button"
                        >
                          added to the top
                        </button>
                        !
                      </span>
                    </Flex>
                  </div>

                  <Flex align="center" className={bem('-image-upload')} gap="sm" justify="start">
                    <ImageUploader kind="secondary" onFinish={handleImageUpload} outline={false} size="sm">
                      <Icon name="upload-cloud" />
                      Host an Image
                    </ImageUploader>
                  </Flex>
                </>
              )}
            </RHFGroup>
          </FormRow>

          <FormRow columns={2} fullWidth>
            <RHFGroup
              control={control}
              description={
                <span>
                  <a href="https://rdmd.readme.io/docs/callouts#custom-icons" rel="noreferrer" target="_blank">
                    Learn more
                  </a>{' '}
                  about custom icons and how to style ReadMe callouts!
                </span>
              }
              id={uid('icons')}
              label="Callout Icons"
              name="appearance.markdown.callouts.icon_font"
              warningMessage={
                calloutIcons === 'emojis' &&
                "Heads up! Callouts that use a custom emoji prefix won't display an icon even when this is checked! You'll need to write your own CSS to map each emoji to an icon."
              }
            >
              {({ field }) => (
                <Select
                  {...field}
                  options={[
                    { label: 'Emoji', value: 'emojis' },
                    { label: 'FontAwesome', value: 'fontawesome' },
                  ]}
                  size="sm"
                />
              )}
            </RHFGroup>
          </FormRow>
        </DemoGate>
      </PageContent>
    </Page>
  );
}

export default CustomCSS;
