import React from 'react';

import useProjectPlan from '@core/hooks/useProjectPlan';

import { Layout } from '@routes/Dash/CustomBlocks/Management';
import { CustomBlockMgmtContextProvider } from '@routes/Dash/CustomBlocks/Management/Context';
import PayGate from '@routes/SuperHub/components/PlanAccess/PayGate';
import { Page } from '@routes/SuperHub/Settings/components';

function ReusableContent() {
  const { planFeatures } = useProjectPlan();

  return (
    <Page renderActionSlot={false}>
      <PayGate access={planFeatures.reusableContent ? 'open' : 'locked'} feature="reusable-content">
        <CustomBlockMgmtContextProvider type="content">
          <Layout />
        </CustomBlockMgmtContextProvider>
      </PayGate>
    </Page>
  );
}

export default ReusableContent;
