import type { ReadVersionType } from '@readme/api/src/mappings/version/types';

import React from 'react';

import Icon from '@ui/Icon';
import { MenuItem } from '@ui/Menu';

import RenameVersionModal from './Modal';
import { useRenameVersionModalContext } from './Modal/Context';

interface Props {
  /** Called when version is successfully renamed. */
  onSuccess?: (version: ReadVersionType['data']) => void;
}

export default function RenameVersion({ onSuccess }: Props) {
  const { setIsRenameModalOpen } = useRenameVersionModalContext();

  return (
    <>
      <MenuItem
        icon={<Icon aria-hidden="true" name="edit-2" />}
        onClick={() => setIsRenameModalOpen(true)}
        TagName="button"
      >
        Rename Version
      </MenuItem>

      <RenameVersionModal onSuccess={onSuccess} />
    </>
  );
}
